import React, { useEffect, useState, useRef } from 'react';
import '../../styles/ComboBanner.scss';

const ComboBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const bannerRef = useRef(null);

  useEffect(() => {
    // Add a slight delay before showing the banner for a smooth entry
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    // Set up intersection observer for scroll reveal
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.2 }
    );
    
    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      clearTimeout(timer);
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  return (
    <div className="combo-banner-wrapper" ref={bannerRef}>
      <div 
        className={`combo-banner ${isVisible ? 'visible' : ''} ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* <div className="combo-banner__badge combo-banner__badge--left">
          <span>100%</span>
          <span>Genuine</span>
        </div> */}
        <div className="combo-banner__text">
          <span className="combo-banner__highlight">Best</span> Seller
        </div>
        {/* <div className="combo-banner__badge combo-banner__badge--right">
          <span>100%</span>
          <span>Genuine</span>
        </div> */}
      </div>
    </div>
  );
};

export default ComboBanner;