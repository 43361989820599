import React, { useState, useEffect } from 'react';
import axios from '../../helpers/axiosconfig';
import { Loader2 } from 'lucide-react';
import '../../styles/AdminContact.scss';

const AdminContact = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get('/admin/contacts');
      setContacts(response.data.contacts);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch contact messages');
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (id, newStatus) => {
    try {
      await axios.put(`/admin/contacts/${id}/status`, { status: newStatus });
      fetchContacts(); // Refresh the list
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  if (loading) {
    return (
      <div className="admin-contact-loading">
        <Loader2 className="spinner" />
        <p>Loading contact messages...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-contact-error">
        <p>{error}</p>
        <button onClick={fetchContacts}>Try Again</button>
      </div>
    );
  }

  return (
    <div className="admin-contact-container">
      <div className="admin-contact-header">
        <h1>Contact Messages</h1>
        <div className="stats">
          <div className="stat-item">
            <span>Total Messages:</span>
            <span>{contacts.length}</span>
          </div>
          <div className="stat-item">
            <span>Pending:</span>
            <span>{contacts.filter(c => c.status === 'pending').length}</span>
          </div>
          <div className="stat-item">
            <span>Responded:</span>
            <span>{contacts.filter(c => c.status === 'responded').length}</span>
          </div>
        </div>
      </div>

      <div className="contact-list">
        {contacts.map((contact) => (
          <div key={contact._id} className={`contact-card ${contact.status}`}>
            <div className="contact-info">
              <h3>{contact.subject}</h3>
              <p className="message">{contact.message}</p>
              <div className="sender-details">
                <p><strong>From:</strong> {contact.fullName}</p>
                <p><strong>Email:</strong> {contact.email}</p>
                <p><strong>Date:</strong> {new Date(contact.createdAt).toLocaleDateString()}</p>
              </div>
            </div>
            <div className="contact-actions">
              <select 
                value={contact.status}
                onChange={(e) => handleStatusUpdate(contact._id, e.target.value)}
                className={`status-select ${contact.status}`}
              >
                <option value="pending">Pending</option>
                <option value="responded">Responded</option>
                <option value="closed">Closed</option>
              </select>
              <a 
                href={`mailto:${contact.email}?subject=Re: ${contact.subject}`}
                className="reply-button"
              >
                Reply via Email
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminContact;