import React, { useState, useEffect, useRef } from 'react';

// Enhanced Product Comparison Slider with Product Showcase elements
const ProductComparisonSlider = () => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [activeFeature, setActiveFeature] = useState(null);
  const showcaseRef = useRef(null);
  const productRef = useRef(null);
  const contentRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1200);
  
  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // Feature labels for each side with improved text and positioning
  const leftFeatures = [
    { text: "NATURAL SURFACTANTS", position: { top: "15%" } },
    { text: "SAFE FOR KIDS", position: { top: "40%" } },
    { text: "100% PERFORMANCE", position: { top: "65%" } },
  ];
  
  const rightFeatures = [
    { text: "BIO-ENZYMES", position: { top: "25%" } },
    { text: "CLEANS 100+ STAINS*", position: { top: "55%" } },
  ];
  
  const alternateLeftFeatures = [
    { text: "TOXIN POWER", position: { top: "15%" } },
    { text: "UNSAFE FOR KIDS", position: { top: "40%" } },
    { text: "50% PERFORMANCE", position: { top: "65%" } },
  ];
  
  const alternateRightFeatures = [
    { text: "HARSH CHEMICALS", position: { top: "25%" } },
    { text: "CLEANS FEW STAINS", position: { top: "55%" } },
  ];
  
  // Feature descriptions for hover effect
  const featureDescriptions = {
    'factory-icon': 'Our products are manufactured using the latest German formulation technology for superior cleaning power.',
    'leaf-icon': 'Experience a long-lasting fresh scent that keeps your clothes smelling wonderful all day.',
    'clock-icon': 'Provides extended cleaning action that continues to work even after washing.',
    'color-icon': 'Preserves the vibrant colors of your clothes, keeping them looking as bright as new.',
    'droplet-icon': 'Advanced color protection technology that prevents fading and color transfer.'
  };

  const handleMove = (clientX) => {
    if (!containerRef.current) return;
    
    const rect = containerRef.current.getBoundingClientRect();
    const position = ((clientX - rect.left) / rect.width) * 100;
    const clampedPosition = Math.min(Math.max(position, 0), 100);
    
    setSliderPosition(clampedPosition);
  };
  
  const handleMouseDown = (e) => {
    e.preventDefault(); // Prevent default browser behavior
    setIsDragging(true);
    handleMove(e.clientX);
    
    // Add a temporary event listener for selection prevention
    document.body.style.userSelect = 'none';
    document.body.style.WebkitUserSelect = 'none';
    document.body.style.MozUserSelect = 'none';
    document.body.style.msUserSelect = 'none';
  };
  
  const handleTouchStart = (e) => {
    e.preventDefault(); // Prevent default browser behavior
    setIsDragging(true);
    handleMove(e.touches[0].clientX);
    
    // Add a temporary event listener for selection prevention
    document.body.style.userSelect = 'none';
    document.body.style.WebkitUserSelect = 'none';
    document.body.style.MozUserSelect = 'none';
    document.body.style.msUserSelect = 'none';
  };
  
  // Handle mouse/touch movement
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        e.preventDefault();
        handleMove(e.clientX);
      }
    };
    
    const handleTouchMove = (e) => {
      if (isDragging) {
        e.preventDefault();
        handleMove(e.touches[0].clientX);
      }
    };
    
    const handleEnd = () => {
      setIsDragging(false);
      
      // Reset body styles when dragging ends
      document.body.style.userSelect = '';
      document.body.style.WebkitUserSelect = '';
      document.body.style.MozUserSelect = '';
      document.body.style.msUserSelect = '';
    };
    
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove, { passive: false });
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchend', handleEnd);
    }
    
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchend', handleEnd);
      
      // Reset body styles when component unmounts or effect cleans up
      document.body.style.userSelect = '';
      document.body.style.WebkitUserSelect = '';
      document.body.style.MozUserSelect = '';
      document.body.style.msUserSelect = '';
    };
  }, [isDragging]);
  
  // Apply visual effects based on slider position to the entire component
  useEffect(() => {
    if (showcaseRef.current) {
      showcaseRef.current.style.setProperty('--slider-position', `${sliderPosition}%`);
    }
  }, [sliderPosition]);
  
  // Determine which product is more visible
  const showGreenProduct = sliderPosition <= 50;
  
  const handleFeatureHover = (iconClass) => {
    setActiveFeature(iconClass);
  };

  const handleFeatureLeave = () => {
    setActiveFeature(null);
  };
  
  // Feature icon component
  const FeatureIcon = ({ iconClass, text }) => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: windowWidth <= 480 ? '0 5px' : '0 10px',
      transform: windowWidth <= 480 ? 'scale(0.85)' : 'scale(1)'
    }}>
      <div style={{
        width: windowWidth <= 480 ? '40px' : '50px',
        height: windowWidth <= 480 ? '40px' : '50px',
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        marginBottom: '8px'
      }} className={iconClass}>
        <span style={{
          fontWeight: 'bold',
          fontSize: windowWidth <= 480 ? '16px' : '18px',
          color: '#066469'
        }}>
          {iconClass.split('-')[0].charAt(0).toUpperCase()}
        </span>
      </div>
      <span style={{
        fontSize: windowWidth <= 480 ? '10px' : '12px',
        fontWeight: 'bold',
        color: '#066469'
      }}>{text}</span>
    </div>
  );
  
  // Product Highlight component
  const ProductHighlight = ({ number, text }) => (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: windowWidth <= 480 ? '10px' : '15px',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      padding: windowWidth <= 480 ? '8px 15px' : '12px 20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }}>
      <div style={{
        fontSize: windowWidth <= 480 ? '20px' : '24px',
        fontWeight: 'bold',
        color: '#066469',
        marginRight: windowWidth <= 480 ? '10px' : '15px'
      }}>{number}</div>
      <div style={{
        fontSize: windowWidth <= 480 ? '12px' : '14px',
        fontWeight: 'bold',
        color: '#066469'
      }}>{text}</div>
    </div>
  );
  
  // Container styles
  const containerStyles = {
    position: 'relative',
    width: '100%',
    maxWidth: '1600px', // Increased from 1200px
    height: windowWidth <= 480 ? '650px' : windowWidth <= 768 ? '750px' : '700px', // Increased height for mobile to accommodate content
    margin: '0 auto',
    overflow: 'hidden',
    borderRadius: '16px',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.12)',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none'
  };
  
  // Background styles
  const backgroundStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(135deg, #e6f7f8 0%, #d3f2f3 100%)',
    zIndex: 0
  };

  // Add subtle pattern overlay to give texture
  const patternOverlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'radial-gradient(circle at 25px 25px, rgba(255, 255, 255, 0.2) 2%, transparent 0%), radial-gradient(circle at 75px 75px, rgba(255, 255, 255, 0.2) 2%, transparent 0%)',
    backgroundSize: '100px 100px',
    zIndex: 1,
    opacity: 0.5
  };
  
  // Main content wrapper styles
  const wrapperStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: windowWidth <= 768 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    zIndex: 2
  };
  
  // Left side product info panel
  const productInfoStyles = {
    width: windowWidth <= 768 ? '100%' : '40%', 
    padding: windowWidth <= 480 ? '15px' : '30px',
    paddingTop: windowWidth <= 768 ? '30px' : '30px', // Extra padding on top for mobile
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 3,
    order: windowWidth <= 768 ? 2 : 1,
    height: windowWidth <= 768 ? '45%' : '100%' // Increased height to push content down
  };

  // Product image container styles
  const productAreaStyles = {
    width: windowWidth <= 768 ? '100%' : '60%',
    height: windowWidth <= 768 ? '55%' : '100%', // Reduced height to give more space to text
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    order: windowWidth <= 768 ? 1 : 2
  };
  
  // Slider styles
  const sliderStyles = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '4px',
    left: `${sliderPosition}%`,
    transform: 'translateX(-50%)',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
  
  // Slider line styles
  const sliderLineStyles = {
    position: 'absolute',
    top: '5%',
    bottom: '5%',
    width: '2px',
    backgroundColor: 'rgba(6, 100, 105, 0.7)',
    boxShadow: '0 0 8px rgba(6, 100, 105, 0.3)'
  };
  
  // Badge styles
  const badgeStyles = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    backgroundColor: '#ff5722',
    color: 'white',
    width: windowWidth <= 480 ? '50px' : '60px',
    height: windowWidth <= 480 ? '50px' : '60px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: windowWidth <= 480 ? '14px' : '16px',
    boxShadow: '0 4px 12px rgba(255, 87, 34, 0.3)',
    zIndex: 10,
    transform: 'rotate(15deg)'
  };

  // Slider handle styles
  const sliderHandleStyles = {
    position: 'absolute',
    width: windowWidth <= 480 ? '40px' : '50px',
    height: windowWidth <= 480 ? '40px' : '50px',
    backgroundColor: '#066469',
    borderRadius: '50%',
    boxShadow: '0 3px 15px rgba(0, 0, 0, 0.25), 0 0 0 4px rgba(255, 255, 255, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'ew-resize',
    transition: 'transform 0.2s ease',
    transform: isDragging ? 'scale(1.1)' : 'scale(1)'
  };
  
  // Enhanced label styles creator function
  const getLabelStyles = (side, position, isVisible) => {
    const opacity = isVisible ? (side === 'left' ? Math.max(0, 1 - (sliderPosition / 60)) : Math.max(0, 1 - ((100 - sliderPosition) / 60))) : 0;
    
    // Adjust top position for mobile layouts
    let topPosition = position.top;
    if (windowWidth <= 768) {
      // Convert percentage to a number for calculations
      const topPercent = parseInt(position.top);
      // Adjust the vertical position based on screen size
      topPosition = `${topPercent * 0.9}%`;
    }
    
    const baseStyles = {
      position: 'absolute',
      padding: windowWidth <= 480 ? '6px 12px' : '10px 20px',
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      color: '#066469',
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      fontSize: windowWidth <= 480 ? '10px' : '14px',
      letterSpacing: '0.5px',
      borderRadius: windowWidth <= 480 ? '16px' : '24px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
      transition: 'opacity 0.3s ease',
      zIndex: 5,
      whiteSpace: 'nowrap',
      top: topPosition,
      opacity: opacity,
      border: '1px solid rgba(6, 100, 105, 0.1)'
    };
    
    if (side === 'left') {
      return {
        ...baseStyles,
        left: windowWidth <= 480 ? '5%' : '10%',
        textAlign: 'right',
        transform: `translateX(-10%) translateY(-50%)`,
      };
    } else {
      return {
        ...baseStyles,
        right: windowWidth <= 480 ? '5%' : '10%',
        textAlign: 'left',
        transform: `translateX(10%) translateY(-50%)`,
      };
    }
  };
  
  // Enhanced line decorations for labels
  const getLineStyles = (side) => {
    const baseStyles = {
      content: '""',
      position: 'absolute',
      top: '50%',
      width: '20px',
      height: '2px',
      backgroundColor: '#066469',
      transform: 'translateY(-50%)',
      opacity: 0.7
    };
    
    if (side === 'left') {
      return {
        ...baseStyles,
        right: '-22px'
      };
    } else {
      return {
        ...baseStyles,
        left: '-22px'
      };
    }
  };

  return (
    <div style={containerStyles} ref={containerRef}>
      <div style={backgroundStyles}></div>
      <div style={patternOverlayStyles}></div>
      
      <div style={wrapperStyles} ref={showcaseRef}>
        {/* Left side product information */}
        <div style={productInfoStyles} ref={contentRef}>
          <div style={{
            display: 'inline-block',
            backgroundColor: '#066469',
            color: 'white',
            padding: windowWidth <= 480 ? '4px 10px' : '6px 12px',
            borderRadius: '20px',
            fontSize: windowWidth <= 480 ? '10px' : '12px',
            fontWeight: 'bold',
            marginBottom: windowWidth <= 480 ? '10px' : '15px',
            alignSelf: 'flex-start'
          }}>
            PREMIUM
          </div>
          
          <h1 style={{
            fontSize: windowWidth <= 480 ? '20px' : windowWidth <= 768 ? '24px' : '28px',
            fontWeight: 'bold',
            color: '#066469',
            marginBottom: windowWidth <= 480 ? '5px' : '10px'
          }}>
            Malak Laundry Liquid Detergent
          </h1>
          
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: windowWidth <= 480 ? '10px' : '15px'
          }}>
            <div style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#066469',
              borderRadius: '50%',
              marginRight: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '12px',
              fontWeight: 'bold'
            }}>H</div>
            <span style={{
              fontSize: windowWidth <= 480 ? '12px' : '14px',
              fontWeight: 'bold',
              color: '#066469'
            }}>HOME CARE</span>
          </div>
          
          <h2 style={{
            fontSize: windowWidth <= 480 ? '16px' : windowWidth <= 768 ? '18px' : '20px',
            fontWeight: 'bold',
            color: '#333',
            marginBottom: windowWidth <= 480 ? '10px' : '15px'
          }}>
            Thick Liquid Detergent
          </h2>
          
          <p style={{
            fontSize: windowWidth <= 480 ? '12px' : '14px',
            fontWeight: 'bold',
            color: '#444',
            marginBottom: windowWidth <= 480 ? '15px' : '20px',
            lineHeight: 1.5
          }}>
            HIGH ACTIVE-MATTER WITH 6 BLEND OF BIO ENZYMES.
          </p>
          
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: windowWidth <= 480 ? '10px' : '20px'
          }}>
            <span style={{
              fontSize: windowWidth <= 480 ? '10px' : '12px',
              color: '#066469',
              fontWeight: 'bold'
            }}>Germs</span>
            
            <div style={{
              flex: 1,
              height: windowWidth <= 480 ? '3px' : '4px',
              backgroundColor: 'rgba(6, 100, 105, 0.2)',
              margin: '0 15px',
              borderRadius: '2px',
              position: 'relative'
            }}>
              <div style={{
                position: 'absolute',
                left: 0,
                top: 0,
                height: '100%',
                width: `${sliderPosition}%`,
                backgroundColor: '#066469',
                borderRadius: '2px',
                transition: 'width 0.3s ease'
              }}></div>
            </div>
            
            <span style={{
              fontSize: windowWidth <= 480 ? '10px' : '12px',
              color: '#066469',
              fontWeight: 'bold'
            }}>Germs</span>
          </div>
          
          <div style={{
            marginBottom: windowWidth <= 480 ? '15px' : '30px',
            marginTop: windowWidth <= 768 ? '15px' : '0'
          }}>
            <ProductHighlight number="6X" text="MORE POWERFUL THAN REGULAR DETERGENTS" />
            <ProductHighlight number="99.9%" text="STAIN REMOVAL" />
          </div>
          
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: windowWidth <= 480 ? '15px' : '30px',
            flexWrap: windowWidth <= 480 ? 'wrap' : 'nowrap'
          }}>
            {windowWidth <= 480 ? (
              // For very small screens, show only 3 icons in first row and 2 in second
              <>
                <div style={{display: 'flex', justifyContent: 'space-around', width: '100%', marginBottom: '10px'}}>
                  <FeatureIcon iconClass="factory-icon" text="FACTORY" />
                  <FeatureIcon iconClass="leaf-icon" text="LEAF" />
                  <FeatureIcon iconClass="clock-icon" text="CLOCK" />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                  <FeatureIcon iconClass="color-icon" text="COLOR" />
                  <FeatureIcon iconClass="droplet-icon" text="DROPLET" />
                </div>
              </>
            ) : (
              // For larger screens, show all 5 in one row
              <>
                <FeatureIcon iconClass="factory-icon" text="FACTORY" />
                <FeatureIcon iconClass="leaf-icon" text="LEAF" />
                <FeatureIcon iconClass="clock-icon" text="CLOCK" />
                <FeatureIcon iconClass="color-icon" text="COLOR" />
                <FeatureIcon iconClass="droplet-icon" text="DROPLET" />
              </>
            )}
          </div>
          
          {activeFeature && (
            <div style={{
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              padding: '15px',
              borderRadius: '8px',
              marginBottom: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              fontSize: '14px',
              color: '#333',
              transition: 'opacity 0.3s ease',
              opacity: 0.9
            }}>
              {featureDescriptions[activeFeature]}
            </div>
          )}
          
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <button style={{
              backgroundColor: '#066469',
              color: 'white',
              border: 'none',
              padding: windowWidth <= 480 ? '8px 20px' : '12px 25px',
              borderRadius: '25px',
              fontWeight: 'bold',
              fontSize: windowWidth <= 480 ? '14px' : '16px',
              cursor: 'pointer',
              boxShadow: '0 4px 12px rgba(6, 100, 105, 0.3)',
              transition: 'all 0.2s ease'
            }}>
              SHOP NOW
            </button>
            
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}>
              <span style={{
                fontSize: windowWidth <= 480 ? '12px' : '14px',
                textDecoration: 'line-through',
                color: '#999'
              }}></span>
              <span style={{
                fontSize: windowWidth <= 480 ? '20px' : '24px',
                fontWeight: 'bold',
                color: '#066469'
              }}></span>
            </div>
          </div>
        </div>
        
        {/* Right side product area */}
        <div style={productAreaStyles} ref={productRef}>
          {/* The colorful product (5LTRWB) - shown when slider is left */}
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: sliderPosition <= 50 ? 1 : 0,
            transition: 'opacity 0.3s ease',
            zIndex: 2,
            pointerEvents: 'none' // Prevent image dragging
          }}>
            <img 
              src="./5LTRWB.png" 
              alt="PUER Home Liquid Detergent"
              style={{ 
                height: windowWidth <= 480 ? '60%' : '80%', 
                width: 'auto', 
                objectFit: 'contain',
                filter: 'drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15))',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                pointerEvents: 'none'
              }}
              draggable="false"
            />
          </div>
          
          {/* The black product (5LTRBLACK) - shown when slider is right */}
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: sliderPosition > 50 ? 1 : 0,
            transition: 'opacity 0.3s ease',
            zIndex: 1,
            pointerEvents: 'none' // Prevent image dragging
          }}>
            <img 
              src="5LTRBLACK.png" 
              alt="Conventional Detergent"
              style={{ 
                height: windowWidth <= 480 ? '110%' : '137%', 
                width: 'auto', 
                objectFit: 'contain',
                filter: 'grayscale(100%) brightness(70%) drop-shadow(0 10px 20px rgba(0, 0, 0, 0.2))',
                opacity: 0.9,
                userSelect: 'none',
                WebkitUserSelect: 'none',
                pointerEvents: 'none'
              }}
              draggable="false"
            />
          </div>
          
          {/* Left feature labels */}
          <div style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '40%',
            left: 0,
            zIndex: 5
          }}>
            {(showGreenProduct ? leftFeatures : alternateLeftFeatures).map((feature, index) => (
              <div 
                key={`left-${index}`} 
                style={getLabelStyles('left', feature.position, showGreenProduct)}
              >
                {feature.text}
                <div style={getLineStyles('left')}></div>
              </div>
            ))}
          </div>
          
          {/* Right feature labels */}
          <div style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '40%',
            right: 0,
            zIndex: 5
          }}>
            {(showGreenProduct ? rightFeatures : alternateRightFeatures).map((feature, index) => (
              <div 
                key={`right-${index}`} 
                style={getLabelStyles('right', feature.position, showGreenProduct)}
              >
                {feature.text}
                <div style={getLineStyles('right')}></div>
              </div>
            ))}
          </div>
          
          {/* Badge */}
          <div style={badgeStyles}>
            NEW
          </div> 
          <div style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            backgroundColor: '#ff5722',
            color: 'white',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            boxShadow: '0 4px 12px rgba(255, 87, 34, 0.3)',
            zIndex: 10,
            transform: 'rotate(15deg)'
          }}>
            NEW
          </div>
          
          {/* Slider */}
          <div style={sliderStyles}>
            <div style={sliderLineStyles}></div>
            <div 
              style={sliderHandleStyles}
              onMouseDown={handleMouseDown}
              onTouchStart={handleTouchStart}
            >
              <div style={{
                position: 'relative',
                width: windowWidth <= 480 ? '16px' : '20px',
                height: windowWidth <= 480 ? '16px' : '20px'
              }}>
                <div style={{
                  position: 'absolute',
                  width: windowWidth <= 480 ? '8px' : '10px',
                  height: '2px',
                  backgroundColor: 'white',
                  left: '1px',
                  top: '50%',
                  transform: 'translateY(-50%) rotate(-45deg)'
                }}></div>
                <div style={{
                  position: 'absolute',
                  width: windowWidth <= 480 ? '8px' : '10px',
                  height: '2px',
                  backgroundColor: 'white',
                  right: '1px',
                  top: '50%',
                  transform: 'translateY(-50%) rotate(45deg)'
                }}></div>
                <div style={{
                  position: 'absolute',
                  width: windowWidth <= 480 ? '8px' : '10px',
                  height: '2px',
                  backgroundColor: 'white',
                  left: '1px',
                  top: '50%',
                  transform: 'translateY(-50%) rotate(45deg)'
                }}></div>
                <div style={{
                  position: 'absolute',
                  width: windowWidth <= 480 ? '8px' : '10px',
                  height: '2px',
                  backgroundColor: 'white',
                  right: '1px',
                  top: '50%',
                  transform: 'translateY(-50%) rotate(-45deg)'
                }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Footer note */}
      <div style={{
        position: 'absolute',
        bottom: windowWidth <= 480 ? '8px' : '15px',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: windowWidth <= 480 ? '10px' : '12px',
        color: '#066469',
        opacity: 0.7,
        fontStyle: 'italic',
        textAlign: 'center',
        width: '80%'
      }}>
        *Based on independent laboratory testing
      </div>
    </div>
  );
};

export default ProductComparisonSlider;