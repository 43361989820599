import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import instance from '../../helpers/axiosconfig';
import '../../styles/orderTracking.scss';

const OrderTracking = () => {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const fetchOrderAndTracking = async () => {
    try {
      setRefreshing(true);
      console.log('Fetching order details for ID:', orderId);
      
      // First get the order details to get the shipment_id
      const orderResponse = await instance.get(`/orders/${orderId}`);
      console.log('Order API response:', orderResponse.data);
      
      // Make sure you're accessing the data structure correctly
      const order = orderResponse.data.order || orderResponse.data;
  
      if (!order) {
        throw new Error('Order not found');
      }
      console.log('Order data extracted:', order);
      
      console.log('Shipping object from order:', order.shipping);
  
      // Initialize tracking data
      let trackingData = null;
      let trackingError = false;
  
      // Check if order has shipping information with shipmentId
      if (order?.shipping?.shipmentId) {
        try {
          console.log('Fetching tracking for shipment ID:', order.shipping.shipmentId);
          const trackingResponse = await instance.get(`/shipping/track/${order.shipping.shipmentId}`);
          // const trackingResponse = await instance.get(`/shipping/track/2770484`);
          
          console.log('Tracking API response:', trackingResponse.data);
          trackingData = trackingResponse.data;
        } catch (tError) {
          console.error('Error fetching tracking data:', tError);
          if (tError.response) {
            console.error('Error response:', tError.response.status, tError.response.data);
          } else if (tError.request) {
            console.error('No response received:', tError.request);
          } else {
            console.error('Error message:', tError.message);
          }
          trackingError = true;
          // Don't throw here - we can still show order info without tracking
        }
      } else {
        console.log('No shipment ID found in order');
        trackingError = true;
      }
  
      // Combine order and tracking data
      setOrderData({
        orderId: order.orderId,
        orderDate: order.createdAt,
        estimatedDelivery: order.shipping?.expectedDeliveryDate || new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
        currentStatus: order.status,
        items: order.items.map(item => ({
          name: item.product?.name || item.productId?.name || 'Product',
          quantity: item.quantity,
          price: item.price,
          image: item.product?.imageUrl || item.productId?.imageUrls?.[0] || "/api/placeholder/80/80"
        })),
        trackingSteps: mapTrackingSteps(trackingData, order.status, trackingError),
        shippingAddress: {
          name: order.shippingAddress.name,
          street: order.shippingAddress.address || order.shippingAddress.street,
          city: order.shippingAddress.city,
          state: order.shippingAddress.state,
          pincode: order.shippingAddress.pincode,
          phone: order.shippingAddress.phone
        }
      });
      setError(null);
    } catch (err) {
      console.error('Error fetching order tracking:', err);
      setError(err.response?.data?.message || 'Unable to fetch tracking information');
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };
  
  useEffect(() => {
    console.log('OrderTracking component mounted with orderId:', orderId);
    fetchOrderAndTracking();
    
    // Set up polling for updates every 5 minutes
    const pollInterval = setInterval(fetchOrderAndTracking, 5 * 60 * 1000);
    
    return () => clearInterval(pollInterval);
  }, [orderId]);

  // Helper function to map iCarry tracking events to our format
  const mapTrackingSteps = (trackingData, orderStatus, trackingError) => {
    console.log('Mapping tracking steps with data:', trackingData);
    console.log('Current order status:', orderStatus);
    console.log('Tracking error occurred:', trackingError);
    
    // Default steps with improved descriptions
    const defaultSteps = [
      {
        status: "ordered",
        title: "Order Placed",
        description: "Your order has been confirmed",
        completed: true,
        timestamp: null
      },
      {
        status: "processed",
        title: "Order Processed",
        description: "Your order has been processed and packed",
        completed: false,
        timestamp: null
      },
      {
        status: "in_transit",
        title: "In Transit",
        description: "Your order is on the way",
        completed: false,
        timestamp: null,
        currentLocation: null
      },
      {
        status: "out_for_delivery",
        title: "Out for Delivery",
        description: "Your order will be delivered today",
        completed: false,
        timestamp: null
      },
      {
        status: "delivered",
        title: "Delivered",
        description: "Your order has been delivered",
        completed: false,
        timestamp: null
      }
    ];
  
    // If there was a tracking error or no tracking data,
    // only mark "Order Placed" as completed and return early
    if (trackingError || !trackingData || trackingData.success !== 1) {
      console.log('No valid tracking data or error occurred. Only marking Order Placed as completed.');
      // Update steps based on order status only for the first step
      defaultSteps.forEach(step => {
        if (step.status === 'ordered') {
          step.completed = true;
        } else if (step.status === orderStatus) {
          step.current = true;
        } else {
          step.completed = false;
          step.current = false;
        }
      });
      return defaultSteps;
    }
    
    // If we're in testing mode with hardcoded tracking ID, modify order status to match tracking
    if (trackingData && trackingData.success === 1) {
      console.log('Using tracking data status instead of order status');
      // Use the tracking data status (lowercase) to better match our step statuses
      orderStatus = trackingData.status?.toLowerCase() || orderStatus;
    }
    
    try {
      console.log('Processing tracking data to update steps');
      
      // Reset all steps for tracking data processing
      defaultSteps.forEach(step => {
        step.completed = false;
        step.current = false;
      });
      
      // Handle the overall status first
      const overallStatus = trackingData.status?.toLowerCase() || '';
      console.log('Overall tracking status:', overallStatus);

      // Mark "Order Placed" as always completed
      const orderedStep = defaultSteps.find(s => s.status === "ordered");
      if (orderedStep) {
        orderedStep.completed = true;
      }
      
      // Update steps based on overall status from tracking API
      if (overallStatus.includes('transit')) {
        // Complete all steps up to in_transit
        defaultSteps.forEach(step => {
          if (["ordered", "processed", "in_transit"].includes(step.status)) {
            step.completed = true;
          }
          if (step.status === "in_transit") {
            step.current = true;
            step.description = trackingData.status || step.description;
          }
        });
      } else if (overallStatus.includes('delivered')) {
        // Complete all steps when delivered
        defaultSteps.forEach(step => {
          step.completed = true;
          if (step.status === "delivered") {
            step.current = true;
            if (trackingData.delivered_datetime) {
              step.timestamp = trackingData.delivered_datetime;
            }
            step.description = "Your order has been delivered successfully";
          }
        });
      } else if (overallStatus.includes('out for delivery')) {
        // Complete all steps up to out_for_delivery
        defaultSteps.forEach(step => {
          if (["ordered", "processed", "in_transit", "out_for_delivery"].includes(step.status)) {
            step.completed = true;
          }
          if (step.status === "out_for_delivery") {
            step.current = true;
            step.description = trackingData.status || step.description;
          }
        });
      } else if (overallStatus.includes('picked') || overallStatus.includes('pickup')) {
        // Complete steps up to processing
        defaultSteps.forEach(step => {
          if (["ordered", "processed"].includes(step.status)) {
            step.completed = true;
          }
          if (step.status === "processed") {
            step.current = true;
            step.description = trackingData.status || step.description;
          }
        });
      }

      // Process detailed events if available
      if (Array.isArray(trackingData.details) && trackingData.details.length > 0) {
        console.log(`Found ${trackingData.details.length} tracking events`);
        
        // Process all tracking events to update steps
        // Process in reverse order to ensure we set timestamps correctly (most recent events override older ones)
        trackingData.details.forEach(event => {
          const eventStatus = event.notes?.toLowerCase() || '';
          console.log('Processing event:', eventStatus, 'at', event.datetime);
          
          // Map event to appropriate step
          let step = null;
          if (eventStatus.includes('picked') || eventStatus.includes('pickup done')) {
            step = defaultSteps.find(s => s.status === 'processed');
            if (step && !step.timestamp) {
              step.timestamp = event.datetime;
              step.completed = true;
            }
          } else if (eventStatus.includes('transit') || eventStatus.includes('forwarded')) {
            step = defaultSteps.find(s => s.status === 'in_transit');
            if (step && !step.timestamp) {
              step.timestamp = event.datetime;
              step.completed = true;
            }
          } else if (eventStatus.includes('out for delivery')) {
            step = defaultSteps.find(s => s.status === 'out_for_delivery');
            if (step && !step.timestamp) {
              step.timestamp = event.datetime;
              step.completed = true;
            }
          } else if (eventStatus.includes('delivered')) {
            step = defaultSteps.find(s => s.status === 'delivered');
            if (step && !step.timestamp) {
              step.timestamp = event.datetime;
              step.completed = true;
            }
          }

          // Update step location and description if applicable
          if (step) {
            // Set current location for relevant steps
            if (event.location) {
              step.currentLocation = event.location;
            }
            
            // Only update description if it provides more information
            if (event.notes && event.notes.length > 10) {
              step.description = event.notes;
            }
          }
        });

        // Set appropriate location and details for the current step based on most recent event
        const mostRecentEvent = trackingData.details[0]; // First item is most recent
        if (mostRecentEvent) {
          console.log('Most recent event:', mostRecentEvent.notes, 'at', mostRecentEvent.datetime);
          // Find the current step based on the most recent event
          let currentStep = null;
          const eventStatus = mostRecentEvent.notes?.toLowerCase() || '';
          
          if (eventStatus.includes('delivered')) {
            currentStep = defaultSteps.find(s => s.status === 'delivered');
          } else if (eventStatus.includes('out for delivery')) {
            currentStep = defaultSteps.find(s => s.status === 'out_for_delivery');
          } else if (eventStatus.includes('transit') || eventStatus.includes('forwarded')) {
            currentStep = defaultSteps.find(s => s.status === 'in_transit');
          } else if (eventStatus.includes('picked') || eventStatus.includes('pickup done')) {
            currentStep = defaultSteps.find(s => s.status === 'processed');
          }
          
          // If we identified a current step from the most recent event, update it
          if (currentStep) {
            currentStep.current = true;
            currentStep.completed = true;
            currentStep.timestamp = mostRecentEvent.datetime || currentStep.timestamp;
            if (mostRecentEvent.location) {
              currentStep.currentLocation = mostRecentEvent.location;
            }
          }
        }
      }
    } catch (error) {
      console.error('Error processing tracking events:', error);
      // Don't throw - fallback to default steps with only Order Placed completed
      defaultSteps.forEach(step => {
        if (step.status === 'ordered') {
          step.completed = true;
        } else {
          step.completed = false;
          step.current = false;
        }
      });
    }

    return defaultSteps;
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Date not available';
    return new Date(dateString).toLocaleString('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handleRefresh = () => {
    console.log('Manually refreshing tracking data...');
    setRefreshing(true);
    fetchOrderAndTracking().finally(() => {
      console.log('Refresh complete');
      setRefreshing(false);
    });
  };

  if (loading) {
    return (
      <div className="tracking-page">
        <div className="loading-spinner">Loading order details...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="tracking-page">
        <div className="error-message">
          <h2>Error</h2>
          <p>{error}</p>
          <button onClick={handleRefresh} className="refresh-button">
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (!orderData) {
    return (
      <div className="tracking-page">
        <div className="no-data-message">
          <h2>No Order Found</h2>
          <p>We couldn't find any tracking information for this order.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="tracking-page">
      <div className="tracking-container">
        <header className="tracking-header">
          <div className="order-info">
            <h1>Track Your Order</h1>
            <div className="order-meta">
              <span className="order-id">Order ID: {orderData.orderId}</span>
              <span className="order-date">
                Ordered on {formatDate(orderData.orderDate)}
              </span>
              <button 
                onClick={handleRefresh} 
                className="refresh-button"
                disabled={refreshing}
              >
                {refreshing ? 'Refreshing...' : 'Refresh Status'}
              </button>
            </div>
          </div>
          {orderData.estimatedDelivery && (
            <div className="estimated-delivery">
              <div className="delivery-icon">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                </svg>
              </div>
              <div className="delivery-info">
                <span className="label">Estimated Delivery</span>
                <span className="date">
                  {new Date(orderData.estimatedDelivery).toLocaleDateString('en-IN', { 
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </span>
              </div>
            </div>
          )}
        </header>

        <div className="tracking-content">
          <div className="tracking-timeline">
            <div className="timeline-header">
              <h2>Shipment Progress</h2>
              {orderData.trackingSteps.find(step => step.current) && (
                <div className="current-status">
                  {orderData.trackingSteps.find(step => step.current)?.title || 'Processing'}
                </div>
              )}
            </div>
            <div className="timeline-steps">
              {orderData.trackingSteps.map((step, index) => (
                <div 
                  key={step.status} 
                  className={`timeline-step ${step.completed ? 'completed' : ''} ${
                    step.current ? 'current' : ''
                  }`}
                >
                  <div className="step-marker">
                    <div className="marker-icon">
                      {step.completed ? (
                        <svg className="check-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                          <path d="M20 6L9 17l-5-5" />
                        </svg>
                      ) : (
                        <div className="marker-dot" />
                      )}
                    </div>
                    {index !== orderData.trackingSteps.length - 1 && (
                      <div className="marker-line" />
                    )}
                  </div>
                  <div className="step-content">
                    <h3>{step.title}</h3>
                    <p>{step.description}</p>
                    {step.timestamp && (
                      <span className="timestamp">{formatDate(step.timestamp)}</span>
                    )}
                    {step.currentLocation && (
                      <div className="current-location">
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" />
                          <circle cx="12" cy="9" r="2.5" />
                        </svg>
                        <span>{step.currentLocation}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="tracking-details">
            {/* <div className="order-items">
              <h2>Order Details</h2>
              <div className="items-list">
                {orderData.items.map((item, index) => (
                  <div key={index} className="item-card">
                    <img src={item.image} alt={item.name} className="item-image" />
                    <div className="item-info">
                      <h3>{item.name}</h3>
                      <div className="item-meta">
                        <span className="quantity">Qty: {item.quantity}</span>
                        <span className="price">{formatPrice(item.price)}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}

            <div className="shipping-info">
              <h2>Delivery Address</h2>
              <div className="address-card">
                <div className="address-icon">
                  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" />
                    <circle cx="12" cy="9" r="2.5" />
                  </svg>
                </div>
                <div className="address-details">
                  <h3>{orderData.shippingAddress.name}</h3>
                  <p>{orderData.shippingAddress.street}</p>
                  <p>
                    {orderData.shippingAddress.city}, {orderData.shippingAddress.state}{' '}
                    {orderData.shippingAddress.pincode}
                  </p>
                  <p className="phone">{orderData.shippingAddress.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;