import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import CartSidebar from '../CartPages/CartPage';
import SignInModal from '../Signin_up/Signin';
import CustomIcon from '../../utils/CustomIcon';
import { useAuth } from '../Signin_up/AuthContext';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import '../../styles/Navbar.scss';
import '../../styles/CartPage.scss';
import { useCart } from '../../utils/CartContext';
import { useCartUI } from '../../utils/CartUIContext';
import { motion, AnimatePresence } from 'framer-motion';

const AnimatedBanner = () => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const [animation, setAnimation] = useState('slideIn');
  const bannerRef = useRef(null);
  
  // Enhanced messages with icons and subtle formatting
  const messages = [
    "✨ Extra 5% OFF on Prepaid Payment ✨",
    "🚚 Free Express Shipping on All Orders 🚚",
    "🎁 Exclusive Limited Collection Available Now 🎁"
  ];

  // Handle message transitions with enhanced timing
  useEffect(() => {
    const interval = setInterval(() => {
      // First fade out current message
      setAnimation('slideOut');
      
      // After animation completes, change message and fade in
      setTimeout(() => {
        setCurrentMessage((prev) => (prev + 1) % messages.length);
        setAnimation('slideIn');
      }, 600);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  // Interactive hover effect
  useEffect(() => {
    const banner = bannerRef.current;
    if (!banner) return;

    const handleMouseMove = (e) => {
      const rect = banner.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      // Calculate position for the highlight effect
      const xPercent = Math.floor((x / rect.width) * 100);
      const yPercent = Math.floor((y / rect.height) * 100);
      
      banner.style.setProperty('--mouse-x', `${xPercent}%`);
      banner.style.setProperty('--mouse-y', `${yPercent}%`);
    };

    banner.addEventListener('mousemove', handleMouseMove);
    return () => {
      banner.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Main banner with premium gradient and light effects
  const bannerStyle = {
    background: 'linear-gradient(135deg, #022e37 0%, #065e68 25%, #09a3ad 50%, #065e68 75%, #022e37 100%)',
    backgroundSize: '300% 300%',
    animation: 'gradientBG 15s ease infinite',
    color: 'white',
    padding: '14px 20px',
    overflow: 'hidden',
    position: 'relative',
    minHeight: '52px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: "'Libre Bodoni', serif",
    boxShadow: '0 4px 20px rgba(0,0,0,0.25), inset 0 1px 0 rgba(255,255,255,0.3)',
    borderBottom: '1px solid rgba(255,255,255,0.1)',
    textAlign: 'center',
    zIndex: 10
  };

  // Message animation styles with enhanced effects
  const getMessageStyle = (isActive) => {
    let transform = 'translateY(30px) scale(0.9)';
    let opacity = 0;
    
    if (isActive) {
      if (animation === 'slideIn') {
        transform = 'translateY(0) scale(1)';
        opacity = 1;
      } else if (animation === 'slideOut') {
        transform = 'translateY(-30px) scale(0.9)';
        opacity = 0;
      }
    }
    
    return {
      position: 'absolute',
      width: '100%',
      left: 0,
      right: 0,
      top: '50%',
      transform: isActive ? `translateY(-50%) ${transform.split(' ')[1] || ''}` : 'translateY(-50%) translateY(30px)',
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 'bold',
      transition: 'all 0.6s cubic-bezier(0.33, 1, 0.68, 1)',
      opacity: opacity,
      lineHeight: '24px',
      letterSpacing: '0.7px',
      textShadow: '0 2px 4px rgba(0,0,0,0.3)',
      margin: '0 auto',
      userSelect: 'none'
    };
  };

  // Particle styles and generation
  const generateParticles = () => {
    const particles = [];
    for (let i = 0; i < 12; i++) {
      const size = Math.floor(Math.random() * 6) + 2;
      const startPositionLeft = Math.floor(Math.random() * 100);
      const startOpacity = Math.random() * 0.5 + 0.3;
      const duration = Math.floor(Math.random() * 20) + 10;
      const delay = Math.floor(Math.random() * 15);
      
      const particleStyle = {
        position: 'absolute',
        left: `${startPositionLeft}%`,
        top: '100%',
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        background: 'rgba(255, 255, 255, 0.7)',
        boxShadow: '0 0 10px 2px rgba(255, 255, 255, 0.3)',
        opacity: startOpacity,
        animation: `float ${duration}s linear ${delay}s infinite`
      };
      
      particles.push(<div key={i} style={particleStyle} />);
    }
    return particles;
  };

  // Dynamic light effect that follows cursor
  const lightEffectStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'radial-gradient(circle at var(--mouse-x) var(--mouse-y), rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 40%)',
    opacity: 0.6,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease'
  };

  return (
    <div ref={bannerRef} style={bannerStyle} className="banner-container">
      <style>
        {`
          @keyframes shimmer {
            0% { left: -150%; }
            100% { left: 150%; }
          }
          
          @keyframes gradientBG {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
          
          @keyframes float {
            0% { transform: translateY(0) rotate(0deg); opacity: var(--starting-opacity); }
            100% { transform: translateY(-120vh) rotate(360deg); opacity: 0; }
          }
          
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.03); }
            100% { transform: scale(1); }
          }
          
          .banner-container:hover .shine-effect {
            left: 150%;
          }
          
          .highlight {
            color: #F8E16C;
            display: inline-block;
            position: relative;
            animation: pulse 2s infinite;
          }
        `}
      </style>
      
      {/* Floating particles effect */}
      {generateParticles()}
      
      {/* Main shimmer effect */}
      <div className="shine-effect" style={{
        position: 'absolute',
        top: 0,
        left: '-150%',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0) 100%)',
        transform: 'skewX(-20deg)',
        animation: 'shimmer 6s infinite',
        animationDelay: '1s'
      }}></div>
      
      {/* Interactive light effect */}
      <div style={lightEffectStyle}></div>
      
      {/* Message container with enhanced centering and visual effects */}
      <div className="relative w-full h-full flex justify-center items-center overflow-hidden">
        <div className="absolute inset-0 flex items-center justify-center">
          {messages.map((message, index) => {
            // Add highlight effect to specific parts of messages
            const highlightedMessage = message.replace(
              /(5% OFF|Express|Limited Collection)/g, 
              '<span class="highlight">$1</span>'
            );
            
            return (
              <div
                key={index}
                style={getMessageStyle(index === currentMessage)}
                className="w-full px-4"
                dangerouslySetInnerHTML={{ __html: highlightedMessage }}
              />
            );
          })}
        </div>
      </div>
      
      {/* Edge borders with light effect */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '1px',
        background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent)'
      }}></div>
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '1px',
        background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)'
      }}></div>
    </div>
  );
};

const SearchBar = ({ isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef(null);
  
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);
  
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          className="search-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="search-container">
            <div className="search-box">
              <SearchIcon className="search-icon" />
              <input
                ref={inputRef}
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search for products..."
                className="search-input"
              />
              <button onClick={onClose} className="search-close-btn">
                <CloseIcon />
              </button>
            </div>
            
            {searchQuery && (
              <motion.div 
                className="search-results"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="search-results-header">
                  <h3>Quick Results</h3>
                  <Link to={`/search?q=${searchQuery}`} onClick={onClose}>View all</Link>
                </div>
                <div className="search-results-empty">
                  {/* Display quick results or empty state */}
                  <p>Try searching for products, collections, etc.</p>
                </div>
              </motion.div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const UserMenu = ({ onClose }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      onClose();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <motion.div 
      className="navbar__user-menu"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.2 }}
    >
      <div className="navbar__user-menu-content">
        <div className="navbar__user-menu-header">
          <div className="navbar__user-avatar">
            {user?.firstName?.charAt(0) || user?.mobileNumber?.charAt(0) || 'U'}
          </div>
          <div className="navbar__user-info">
            <span className="navbar__user-mobile">{user?.mobileNumber}</span>
            {user?.email && <span className="navbar__user-email">{user?.email}</span>}
          </div>
        </div>
        
        <div className="navbar__user-menu-divider"></div>
        
        <div className="navbar__user-menu-options">
          <button 
            onClick={() => {
              navigate('/profile');
              onClose();
            }}
            className="navbar__user-menu-item"
          >
            <CustomIcon name="user-circle" className="navbar__user-menu-icon" />
            <span>My Profile</span>
          </button>
          <button 
            onClick={() => {
              navigate('/orders');
              onClose();
            }}
            className="navbar__user-menu-item"
          >
            <CustomIcon name="package" className="navbar__user-menu-icon" />
            <span>My Orders</span>
          </button>
          <button 
            onClick={() => {
              navigate('/wishlist');
              onClose();
            }}
            className="navbar__user-menu-item"
          >
            <CustomIcon name="heart" className="navbar__user-menu-icon" />
            <span>Wishlist</span>
          </button>
          <button 
            onClick={handleLogout}
            className="navbar__user-menu-item navbar__user-menu-item--logout"
          >
            <CustomIcon name="log-out" className="navbar__user-menu-icon" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const NavLinks = ({ isMobile, onClose }) => {
  const location = useLocation();
  
  const links = [
    { path: '/', label: 'Home' },
    { path: '/products', label: 'Shop' },
    { path: '/about', label: 'About' },
    { path: '/contact', label: 'Contact' }
  ];
  
  return (
    <div className={`navbar__links ${isMobile ? 'navbar__links--mobile' : ''}`}>
      {links.map((link) => (
        <Link 
          key={link.path}
          to={link.path} 
          className={`navbar__link ${location.pathname === link.path ? 'navbar__link--active' : ''}`}
          onClick={() => isMobile && onClose()}
        >
          {link.label}
          {!isMobile && location.pathname === link.path && (
            <motion.div 
              className="navbar__link-indicator" 
              layoutId="nav-indicator"
              transition={{ type: 'spring', duration: 0.5 }}
            />
          )}
        </Link>
      ))}
    </div>
  );
};

const ResponsiveNavbar = () => {
  const { isCartOpen, openCart, closeCart, toggleCart } = useCartUI();
  const [showIntro, setShowIntro] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const navbarRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showUserMenu && !event.target.closest('.navbar__user-container')) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showUserMenu]);

  useEffect(() => {
    const timer = setTimeout(() => setShowIntro(false), 5);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleCartUpdate = (event) => setCart(event.detail.cart);
    const handleStorageChange = () => {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      setCart(storedCart);
    };

    window.addEventListener('cartUpdate', handleCartUpdate);
    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('cartUpdate', handleCartUpdate);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  
  const handleUserClick = () => {
    if (isAuthenticated) {
      setShowUserMenu(!showUserMenu);
    } else {
      setIsSignInOpen(true);
    }
  };
  
  const handleSearchToggle = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <>
      {showIntro && (
        <motion.div 
          className="intro-overlay"
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 2 }}
        >
          <motion.img 
            src="https://ik.imagekit.io/iafgy06va/malak.png?updatedAt=1737543407799" 
            alt="Malak Logo" 
            className="intro-logo" 
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1.5, ease: "easeOut" }}
          />
        </motion.div>
      )}

      <div className={showIntro ? 'hidden' : ''}>
        <AnimatedBanner />
      </div>

      <motion.nav 
  ref={navbarRef}
  className={`navbar ${showIntro ? 'hidden' : ''} ${isMobile ? 'navbar--mobile' : ''} ${scrolled ? 'navbar--scrolled' : ''}`}
  initial={{ y: -100 }}
  animate={{ y: 0 }}
  transition={{ delay: 0.5, duration: 0.3, ease: "easeOut" }}
>
        <div className="navbar__container">
          <div className="navbar__left">
            <button 
              className="navbar__sidebar-toggle" 
              onClick={toggleSidebar}
              aria-label="Toggle sidebar"
            >
              <div className="icon-container">
                {isSidebarOpen ? (
                  <CloseIcon className="rotating-enter" />
                ) : (
                  <FormatListBulletedIcon className="rotating-enter" />
                )}
              </div>
            </button>

            <div className="navbar__logo">
              <Link to="/">
                <motion.img 
                  src="/MalakLogo.png" 
                  alt="Malak Logo" 
                  className={`navbar__logo-img ${showIntro ? '' : 'animated-logo'}`}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                />
              </Link>
            </div>
          </div>

          {!isMobile && <NavLinks isMobile={false} />}

          <div className="navbar__actions">
            <button 
              className="navbar__action-btn" 
              onClick={handleSearchToggle}
              aria-label="Search"
            >
              <SearchIcon className="navbar__icon" />
            </button>

            <div className="navbar__user-container">
              <button 
                onClick={handleUserClick}
                className="navbar__action-btn"
                aria-label={isAuthenticated ? "User menu" : "Sign in"}
              >
                <CustomIcon name="user" className="navbar__icon navbar__user-icon-svg" />
              </button>
              <AnimatePresence>
                {showUserMenu && isAuthenticated && (
                  <UserMenu onClose={() => setShowUserMenu(false)} />
                )}
              </AnimatePresence>
            </div>

            <motion.button 
              className="navbar__action-btn navbar__cart-btn" 
              onClick={toggleCart}
              aria-label="Shopping cart"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              <CustomIcon name="shopping" className="navbar__icon navbar__cart-icon-svg" />
              {cart.length > 0 && (
                <motion.span 
                  className="cart-count"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  {cart.length}
                </motion.span>
              )}
            </motion.button>
          </div>
        </div>
      </motion.nav>

      <SearchBar 
        isOpen={isSearchOpen} 
        onClose={() => setIsSearchOpen(false)} 
      />

      <Sidebar 
        isOpen={isSidebarOpen} 
        onClose={() => setIsSidebarOpen(false)}
        onOpenSignIn={() => setIsSignInOpen(true)}
      >
        {isMobile && <NavLinks isMobile={true} onClose={() => setIsSidebarOpen(false)} />}
      </Sidebar>

      <CartSidebar />
      
      <SignInModal
        isOpen={isSignInOpen}
        onClose={() => setIsSignInOpen(false)}
        onLoginSuccess={() => {
          setIsSignInOpen(false);
        }}
      />
    </>
  );
};

export default ResponsiveNavbar;