import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/ProductShowcase.scss'; 

const FeatureIcon = ({ iconClass, text }) => (
  <div className="feature-icon">
    <div className={`icon-wrapper ${iconClass}`}></div>
    <span>{text}</span>
  </div>
);

const Bubble = ({ onPop, index }) => {
  const [popped, setPopped] = useState(false);
  const bubbleRef = useRef(null);

  useEffect(() => {
    const bubble = bubbleRef.current;
    const duration = 5000 + Math.random() * 5000;
    const delay = index * 500; // Reduced delay between bubbles

    bubble.style.animationDuration = `${duration}ms`;
    bubble.style.animationDelay = `${delay}ms`;

    const timer = setTimeout(() => {
      setPopped(true);
      onPop();
    }, duration + delay);

    return () => clearTimeout(timer);
  }, [onPop, index]);

  return (
    <div 
      ref={bubbleRef}
      className={`bubble ${popped ? 'popped' : ''}`} 
      style={{
        left: `${Math.random() * 80 + 10}%`,
        // Randomize bubble size
        width: `${30 + Math.random() * 40}px`,
        height: `${30 + Math.random() * 40}px`,
        opacity: Math.random() * 0.5 + 0.5,
      }}
    ></div>
  );
};

// New component for product highlights
const ProductHighlight = ({ number, text }) => (
  <div className="product-highlight">
    <div className="highlight-number">{number}</div>
    <div className="highlight-text">{text}</div>
  </div>
);

const ProductShowcase = () => {
  const showcaseRef = useRef(null);
  const productImageRef = useRef(null);
  const [bubbles, setBubbles] = useState([]);
  const [bubbleCount, setBubbleCount] = useState(0);
  const [showFormulation, setShowFormulation] = useState(false);
  const [activeFeature, setActiveFeature] = useState(null);
  const navigate = useNavigate();

  // Default product and variation IDs from the data
  const defaultProductId = "6744819219aecc37a125eb29"; // Main product ID
  const defaultVariationId = "6744819219aecc37a125eb2a"; // First variation ID (1 LTR)

  const handleQuickView = () => {
    navigate(`/products?category=LAUNDRY CARE`, {
      state: { 
        selectedProductId: defaultProductId,
        selectedVariationId: defaultVariationId
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          showcaseRef.current.classList.add('animate-in');
          setTimeout(() => setShowFormulation(true), 1000);
        }
      },
      { threshold: 0.1 }
    );

    if (showcaseRef.current) {
      observer.observe(showcaseRef.current);
    }

    return () => {
      if (showcaseRef.current) {
        observer.unobserve(showcaseRef.current);
      }
    };
  }, []);

  // Fixed animation for product image
  useEffect(() => {
    if (productImageRef.current) {
      const image = productImageRef.current;
      
      // Add subtle hover effect instead of continuous animation
      const handleMouseMove = (e) => {
        const { left, top, width, height } = image.getBoundingClientRect();
        const x = (e.clientX - left) / width - 0.5;
        const y = (e.clientY - top) / height - 0.5;
        
        image.style.transform = `perspective(1000px) rotateY(${x * 5}deg) rotateX(${-y * 5}deg) translateZ(10px)`;
      };
      
      const handleMouseLeave = () => {
        image.style.transform = 'perspective(1000px) rotateY(0) rotateX(0) translateZ(0)';
        image.style.transition = 'transform 0.5s ease';
      };
      
      const container = image.parentElement;
      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('mouseleave', handleMouseLeave);
      
      return () => {
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (bubbles.length < 15) { // Limit total bubbles
        setBubbles(prevBubbles => [...prevBubbles, bubbleCount]);
        setBubbleCount(prevCount => prevCount + 1);
      }
    }, 800); // Increased interval for less frequent bubbles

    return () => clearInterval(interval);
  }, [bubbleCount, bubbles.length]);

  const handleBubblePop = () => {
    setBubbles(prevBubbles => {
      const newBubbles = [...prevBubbles];
      newBubbles.shift(); // Remove the oldest bubble
      return newBubbles;
    });
  };

  // Feature descriptions for hover effect
  const featureDescriptions = {
    'factory-icon': 'Our products are manufactured using the latest German formulation technology for superior cleaning power.',
    'leaf-icon': 'Experience a long-lasting fresh scent that keeps your clothes smelling wonderful all day.',
    'clock-icon': 'Provides extended cleaning action that continues to work even after washing.',
    'color-icon': 'Preserves the vibrant colors of your clothes, keeping them looking as bright as new.',
    'droplet-icon': 'Advanced color protection technology that prevents fading and color transfer.'
  };

  const handleFeatureHover = (iconClass) => {
    setActiveFeature(iconClass);
  };

  const handleFeatureLeave = () => {
    setActiveFeature(null);
  };

  return (
    <div className="product-showcase" ref={showcaseRef}>
      <div className="background-pattern"></div>
      
      <div className="content-wrapper">
        <div className="product-info">
          <div className="brand-badge">
            <span>PREMIUM</span>
          </div>
          
          <h1 className="title">Malak Laundry Liquid Detergent</h1>
          
          <div className="category">
            <div className="icon home-icon"></div>
            <span>HOME CARE</span>
          </div>
          
          <h2 className="product-name">Thick Liquid Detergent</h2>
          
          <p className="description">
            HIGH ACTIVE-MATTER WITH 6 BLEND OF BIO ENZYMES.
          </p>
          
          <div className="highlights">
            <ProductHighlight number="6X" text="MORE POWERFUL THAN REGULAR DETERGENTS" />
            <ProductHighlight number="99.9%" text="STAIN REMOVAL" />
          </div>
          
          <div className="features">
            {Object.entries(featureDescriptions).map(([iconClass, description]) => (
              <div key={iconClass} className="feature-container">
                <FeatureIcon 
                  iconClass={iconClass} 
                  text={iconClass.split('-')[0].toUpperCase()}
                  onMouseEnter={() => handleFeatureHover(iconClass)}
                  onMouseLeave={handleFeatureLeave}
                />
              </div>
            ))}
          </div>
          
          {activeFeature && (
            <div className="feature-description">
              {featureDescriptions[activeFeature]}
            </div>
          )}
          
          <div className="cta-container">
            <button className="shop-now" onClick={handleQuickView}>
              SHOP NOW
            </button>
            <div className="price">
              <span className="old-price"></span>
              <span className="current-price"></span>
            </div>
          </div>
        </div>
      </div>
      
      <div className="image-wrapper">
        <div className="image-container">
          <img 
            ref={productImageRef}
            src="https://ik.imagekit.io/srwardmxk/5LTRB_inFAVPhK00.png" 
            alt="Malak Bathroom Cleaner" 
            className="product-image" 
          />
        </div>
        
        <div className="glow-effect"></div>
        
        {showFormulation && (
          <div className="formulation-info">
            <div className="formulation-line">
              <div className="formulation-dot"></div>
              <div className="formulation-label">Bio Enzymes</div>
            </div>
            <div className="formulation-line" style={{ top: '55%', left: '60%' }}>
              <div className="formulation-dot"></div>
              <div className="formulation-label">Color Protection</div>
            </div>
          </div>
        )}
        
        <div className="bubbles">
          {bubbles.map((id) => (
            <Bubble key={id} onPop={handleBubblePop} index={id % 10} />
          ))}
        </div>
        
        <div className="product-badge">
          NEW
        </div>
      </div>
    </div>
  );
};

export default ProductShowcase;