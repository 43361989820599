import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp, FileText } from 'lucide-react';
import '../../styles/admin.scss';
import axios from '../../helpers/axiosconfig';
import html2pdf from 'html2pdf.js';

export const AdminLogin = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (credentials.username === 'admin' && credentials.password === 'Malak##123') {
      localStorage.setItem('adminToken', 'dummy-token');
      navigate('/admin/orders');
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="admin-login">
      <div className="admin-login__container">
        <h1 className="admin-login__title">Admin Login</h1>
        
        {error && (
          <div className="admin-login__error">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="admin-login__form">
          <div className="admin-login__input-group">
            <label>Username</label>
            <input
              type="text"
              value={credentials.username}
              onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
              required
            />
          </div>
          
          <div className="admin-login__input-group">
            <label>Password</label>
            <input
              type="password"
              value={credentials.password}
              onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
              required
            />
          </div>

          <button type="submit" className="admin-login__button">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export const AdminOrders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedOrders, setExpandedOrders] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
const [filteredOrders, setFilteredOrders] = useState([]);

useEffect(() => {
  if (!searchTerm.trim()) {
    setFilteredOrders(orders);
    return;
  }
  
  const lowerSearchTerm = searchTerm.toLowerCase().trim();
  
  const filtered = orders.filter(order => 
    // Order ID
    (order.orderId && order.orderId.toLowerCase().includes(lowerSearchTerm)) ||
    // Customer name
    (order.shippingAddress?.name && order.shippingAddress.name.toLowerCase().includes(lowerSearchTerm)) ||
    // Mobile number
    (order.shippingAddress?.phone && order.shippingAddress.phone.includes(lowerSearchTerm)) ||
    // Email
    (order.shippingAddress?.email && order.shippingAddress.email.toLowerCase().includes(lowerSearchTerm)) ||
    // Address fields
    (order.shippingAddress?.street && order.shippingAddress.street.toLowerCase().includes(lowerSearchTerm)) ||
    (order.shippingAddress?.city && order.shippingAddress.city.toLowerCase().includes(lowerSearchTerm)) ||
    (order.shippingAddress?.state && order.shippingAddress.state.toLowerCase().includes(lowerSearchTerm)) ||
    (order.shippingAddress?.pincode && order.shippingAddress.pincode.includes(lowerSearchTerm)) ||
    // Payment method
    (order.paymentMethod && order.paymentMethod.toLowerCase().includes(lowerSearchTerm)) ||
    // Status
    (order.status && order.status.toLowerCase().includes(lowerSearchTerm)) ||
    // Payment status
    (order.paymentStatus && order.paymentStatus.toLowerCase().includes(lowerSearchTerm))
  );
  
  setFilteredOrders(filtered);
}, [searchTerm, orders]);

// Add a search handler function
const handleSearch = (e) => {
  setSearchTerm(e.target.value);
};

// Add a clear search function
const clearSearch = () => {
  setSearchTerm('');
};

  // Constants for invoice generation
  const COD_CHARGES = 25;
  const GSTIN = '24IDRPS6991G1ZK';

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/admin/orders');
      
      if (response.data.success) {
        setOrders(response.data.orders);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch orders');
      console.error('Error fetching orders:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin');
  };

  const toggleOrderExpansion = (orderId) => {
    setExpandedOrders(prev => ({
      ...prev,
      [orderId]: !prev[orderId]
    }));
  };

  const getStatusClass = (status) => {
    switch (status?.toLowerCase()) {
      case 'delivered':
        return 'admin-orders__status--delivered';
      case 'processing':
        return 'admin-orders__status--processing';
      case 'shipped':
        return 'admin-orders__status--shipped';
      default:
        return '';
    }
  };

  const formatAddress = (shippingAddress) => {
    if (!shippingAddress) return 'N/A';
    const { name, street, city, state, pincode } = shippingAddress;
    
    const parts = [];
    if (name) parts.push(name);
    if (street) parts.push(street);
    if (city) parts.push(city);
    if (state) parts.push(state);
    if (pincode) parts.push(pincode);
    
    return parts.join(', ');
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Format invoice number with M/71721 prefix
  const formatInvoiceNumber = (number) => {
    if (!number && number !== 0) return 'M/71721/N/A';
    return `M/71721/${number}`;
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 2,
    }).format(price);
  };

  const getProductDetails = (item) => {
    const variation = item.productId?.variations?.find(v => v._id === item.variationId);
    return {
      name: variation?.name || 'Product Name Not Available',
      price: item.price || 0,
      volume: variation?.volume || 'N/A',
      discount: variation?.discount || 0
    };
  };

  const calculateDiscount = (price, discount) => {
    return price * (discount / 100);
  };

  const calculateDeliveryGST = (deliveryCharge) => {
    const baseAmount = Math.round((deliveryCharge / 1.18) * 100) / 100;
    const singleGSTAmount = Math.round((baseAmount * 0.09) * 100) / 100;

    return {
      baseDeliveryAmount: baseAmount,
      deliveryGSTAmount: singleGSTAmount
    };
  };

  const calculateGSTFromTotal = (totalAmount, paymentMethod = '') => {
    const isCOD = (paymentMethod || '').toLowerCase() === 'cod';
    
    const deliveryGST = isCOD ? calculateDeliveryGST(COD_CHARGES) : {
      baseDeliveryAmount: 0,
      deliveryGSTAmount: 0
    };

    const amountBeforeGST = isCOD ? totalAmount - COD_CHARGES : totalAmount;

    const baseAmount = Math.round((amountBeforeGST / 1.18) * 100) / 100;
    const singleGSTAmount = Math.round((baseAmount * 0.09) * 100) / 100;
    
    return {
      baseAmount,
      gstAmount: singleGSTAmount,
      codCharges: isCOD ? COD_CHARGES : 0,
      baseDeliveryAmount: deliveryGST.baseDeliveryAmount,
      deliveryGSTAmount: deliveryGST.deliveryGSTAmount
    };
  };

  const handleGenerateInvoice = (order) => {
    if (!order) return;

    const { 
      baseAmount, 
      gstAmount, 
      codCharges,
      baseDeliveryAmount,
      deliveryGSTAmount 
    } = calculateGSTFromTotal(order.totalAmount, order.paymentMethod);

    const invoice = document.createElement('div');
    invoice.innerHTML = `
      <div class="invoice-pdf" style="padding: 40px; max-width: 800px; margin: 0 auto; font-family: Arial, sans-serif;">
        <div style="text-align: center; margin-bottom: 30px;">
          <h1 style="font-size: 24px; color: #1a1a1a; margin-bottom: 10px;">Invoice ${formatInvoiceNumber(order.invoiceNumber)}</h1>
          <p style="color: #4b5563;">Order ID: ${order.orderId || order._id}</p>
        </div>

        <div style="margin-bottom: 30px;">
          <table style="width: 100%; margin-bottom: 20px;">
            <tr>
              <td style="width: 50%; vertical-align: top; padding-right: 20px;">
                <div style="margin-bottom: 20px;">
                  <h3 style="font-size: 16px; color: #374151; margin-bottom: 10px;">From:</h3>
                  <p style="margin: 5px 0; color: #4b5563;">Periodic Industries</p>
                  <p style="margin: 5px 0; color: #4b5563;">PLOT NO 65, SURVEY NO 213-214</p>
                  <p style="margin: 5px 0; color: #4b5563;">LAKHABAVAD, Jamnagar, Gujarat, 361006</p>
                  <p style="margin: 5px 0; color: #4b5563;">GSTIN: ${GSTIN}</p>
                </div>
              </td>
              <td style="width: 50%; vertical-align: top;">
                <div style="margin-bottom: 20px;">
                  <h3 style="font-size: 16px; color: #374151; margin-bottom: 10px;">To:</h3>
                  <p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.name || 'N/A'}</p>
<p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.phone || 'N/A'}</p>
<p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.email || 'N/A'}</p>
<p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.street || 'N/A'}</p>
<p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.city || 'N/A'}, ${order.shippingAddress?.state || 'N/A'}</p>
<p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.pincode || 'N/A'}</p>
                </div>
              </td>
            </tr>
          </table>

          <table style="width: 100%; margin-bottom: 20px; background-color: #f9fafb; border-radius: 6px; padding: 15px;">
            <tr>
              <td style="width: 50%;">
                <p style="margin: 0; color: #6b7280; font-size: 14px;">Order Date:</p>
                <p style="margin: 5px 0 0; color: #1f2937; font-weight: 500;">${formatDate(order.createdAt)}</p>
              </td>
              <td style="width: 50%;">
                <p style="margin: 0; color: #6b7280; font-size: 14px;">Payment Details:</p>
                <p style="margin: 5px 0 0; color: #1f2937; font-weight: 500;">
                  ${order.paymentMethod.toUpperCase()} - ${order.paymentStatus.toUpperCase()}
                </p>
              </td>
            </tr>
          </table>
        </div>

        <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
          <thead>
            <tr style="background-color: #f3f4f6;">
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Item</th>
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Volume</th>
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Quantity</th>
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Price</th>
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Discount</th>
              <th style="padding: 12px; text-align: right; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Total</th>
            </tr>
          </thead>
          <tbody>
            ${order.items.map(item => {
              const details = getProductDetails(item);
              const discountAmount = calculateDiscount(details.price, details.discount);
              return `
                <tr>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${details.name}</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${details.volume}</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${item.quantity}</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${formatPrice(details.price)}</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${details.discount}% (${formatPrice(discountAmount)})</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563; text-align: right;">${formatPrice(item.price * item.quantity)}</td>
                </tr>
              `;
            }).join('')}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563; border-top: 2px solid #e5e7eb;">Base Amount:</td>
              <td style="padding: 12px; color: #4b5563; border-top: 2px solid #e5e7eb; text-align: right;">${formatPrice(baseAmount)}</td>
            </tr>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">CGST (9%):</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(gstAmount)}</td>
            </tr>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">SGST (9%):</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(gstAmount)}</td>
            </tr>
            ${codCharges > 0 ? `
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">Delivery Charges:</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(baseDeliveryAmount)}</td>
            </tr>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">Delivery CGST (9%):</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(deliveryGSTAmount)}</td>
            </tr>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">Delivery SGST (9%):</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(deliveryGSTAmount)}</td>
            </tr>
            ` : ''}
            <tr>
              <td colspan="5" style="padding: 12px; font-weight: 600; color: #1f2937;">Total Amount:</td>
              <td style="padding: 12px; font-weight: 600; color: #1f2937; text-align: right;">${formatPrice(order.totalAmount)}</td>
            </tr>
          </tfoot>
        </table>

        <div style="margin-top: 40px; text-align: center; color: #6b7280; font-size: 14px;">
          <p>Thank you for your Order!</p>
          <p>This is a computer-generated receipt. No signature is required.</p>
        </div>
      </div>
    `;

    const opt = {
      margin: [15, 15, 15, 15],
      filename: `invoice-${formatInvoiceNumber(order.invoiceNumber)}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        logging: false
      },
      jsPDF: { 
        unit: 'mm', 
        format: 'a4', 
        orientation: 'portrait'
      }
    };

    html2pdf().from(invoice).set(opt).save();
  };

  return (
    <div className="admin-orders">
      <nav className="admin-orders__navbar">
        <div className="admin-orders__navbar-content">
          <h1 className="admin-orders__navbar-title">Admin Dashboard</h1>
          <button
            onClick={handleLogout}
            className="admin-orders__navbar-logout"
          >
            Logout
          </button>
        </div>
      </nav>

      <main className="admin-orders__content">
        <div className="admin-orders__card">
          <h2 className="admin-orders__title">Orders Management</h2>
          <div className="admin-orders__search-container">
  <div className="admin-orders__search-wrapper">
    <input
      type="text"
      value={searchTerm}
      onChange={handleSearch}
      placeholder="Search by name, phone, order ID, status..."
      className="admin-orders__search-input"
    />
    {searchTerm && (
      <button 
        onClick={clearSearch}
        className="admin-orders__search-clear-btn"
      >
        ✕
      </button>
    )}
  </div>
  {searchTerm && (
    <div className="admin-orders__search-results">
      Found {filteredOrders.length} {filteredOrders.length === 1 ? 'order' : 'orders'}
    </div>
  )}
</div>
          
          {loading && (
            <div className="admin-orders__loading">Loading orders...</div>
          )}

          {error && (
            <div className="admin-orders__error">
              Error: {error}
            </div>
          )}

{!loading && !error && (
  <div className="admin-orders__table-container">
    <table className="admin-orders__table">
      <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Customer</th>
                    <th>Mobile</th>
                    <th>Address</th>
                    <th>Payment Mode</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Payment Status</th>
                    <th>Total</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
        {filteredOrders.map((order) => (
                 
                    <React.Fragment key={order._id}>
                      <tr>
                        <td>{order.orderId || order._id}</td>
                        <td>{order.shippingAddress?.name || 'N/A'}</td>
                        <td>{order.shippingAddress?.phone || 'N/A'}</td>
                        <td>{formatAddress(order.shippingAddress)}</td>
                        <td>
                          <span className="admin-orders__payment-mode">
                            {order.paymentMethod === 'cod' ? 'COD' : 'Prepaid'}
                          </span>
                        </td>
                        <td>{formatDate(order.createdAt)}</td>
                        <td>
                          <span className={`admin-orders__status ${getStatusClass(order.status)}`}>
                            {order.status}
                          </span>
                        </td>
                        <td>
                          <span className="admin-orders__payment-status">
                            {order.paymentStatus}
                          </span>
                        </td>
                        <td>₹{order.totalAmount.toFixed(2)}</td>
                        <td>
                          <div className="admin-orders__actions">
                            <button 
                              onClick={() => toggleOrderExpansion(order._id)}
                              className="admin-orders__expand-btn"
                              title="View Details"
                            >
                              {expandedOrders[order._id] ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                            </button>
                            <button 
                              onClick={() => handleGenerateInvoice(order)}
                              className="admin-orders__invoice-btn"
                              title="Download Invoice"
                            >
                              <FileText size={18} />
                            </button>
                          </div>
                        </td>
                      </tr>
                      {expandedOrders[order._id] && (
                        <tr className="admin-orders__expanded-row">
                          <td colSpan="10">
                            <div className="admin-orders__expanded-content">
                              <h3 className="admin-orders__subtitle">Order Details</h3>
                              <div className="admin-orders__customer-details">
                                <p><strong>Customer:</strong> {order.shippingAddress?.name || 'N/A'}</p>
                                <p><strong>Mobile:</strong> {order.shippingAddress?.phone || 'N/A'}</p>
                                <p><strong>Email:</strong> {order.shippingAddress?.email || 'N/A'}</p>
                              </div>
                              {order.shipping?.courier && (
                                <p><strong>Courier Service:</strong> {order.shipping.courier}</p>
                              )}
                              {order.shipping?.shipmentId && (
                                <p><strong>Shipment ID:</strong> {order.shipping.shipmentId}</p>
                              )}
                              <h3 className="admin-orders__subtitle">Products</h3>
                              <table className="admin-orders__products-table">
                                <thead>
                                  <tr>
                                    <th>Product</th>
                                    <th>Category</th>
                                    <th>Volume</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {order.items.map((item) => {
                                    const variant = item.productId.variations.find(v => v._id === item.variationId);
                                    return (
                                      <tr key={item._id}>
                                        <td>{variant?.name}</td>
                                        <td>{item.productId.category}</td>
                                        <td>{variant?.volume}</td>
                                        <td>{item.quantity}</td>
                                        <td>₹{item.price.toFixed(2)}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                  </tbody>
                </table>
              </div>
            )}
        </div>
      </main>
    </div>
  );
};