import React, { useState, useRef, useEffect } from 'react';
import { Volume2, VolumeX, Play, Pause, Maximize } from 'lucide-react';

const VideoPlayer = ({ src = "./Video_converted.mp4" }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.video-outer-container {
  padding: 3px;
  background: linear-gradient(145deg, #2d2f77, #5b42ab, #724bb7, #4f27cd);
  border-radius: 16px;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  box-shadow: 0 10px 30px rgba(45, 47, 119, 0.3),
              0 4px 8px rgba(91, 66, 171, 0.2),
              0 20px 40px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
}

/* Add a subtle glow effect */
.video-outer-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(114, 75, 183, 0.4), rgba(79, 39, 205, 0.4));
  filter: blur(20px);
  opacity: 0.7;
  z-index: -1;
  border-radius: 16px;
}

.video-player-container {
  position: relative;
  width: 100%;
  background: #0f0f1a;
  border-radius: 14px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.video-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  width: 100%;
}

.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 15, 26, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  backdrop-filter: blur(4px);
}

.spinner {
  width: 60px;
  height: 60px;
  border: 3px solid rgba(114, 75, 183, 0.3);
  border-radius: 50%;
  border-top-color: #724bb7;
  box-shadow: 0 0 10px rgba(114, 75, 183, 0.8);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.controls-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 20px 20px;
  background: linear-gradient(
    0deg,
    rgba(15, 15, 26, 0.95) 0%,
    rgba(15, 15, 26, 0.8) 40%,
    rgba(15, 15, 26, 0.4) 70%,
    transparent 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(5px);
  backdrop-filter: blur(3px);
}

.video-player-container:hover .controls-overlay,
.video-player-container:active .controls-overlay {
  opacity: 1;
  transform: translateY(0);
}

.controls-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.controls-left {
  display: flex;
  align-items: center;
  gap: 18px;
}

.controls-right {
  display: flex;
  align-items: center;
}

.control-button {
  background: transparent;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.control-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(114, 75, 183, 0.2);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.2s ease-out;
}

.control-button:hover::before {
  transform: scale(1);
}

.control-button:hover {
  transform: scale(1.1);
}

.volume-button {
  position: relative;
  display: flex;
  align-items: center;
}

.volume-slider {
  width: 0;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  transition: width 0.3s ease;
  overflow: hidden;
  margin-left: 10px;
}

.volume-button:hover .volume-slider {
  width: 80px;
}

.volume-filled {
  height: 100%;
  background: linear-gradient(90deg, #724bb7, #4f27cd);
  border-radius: 4px;
}

.progress-bar {
  position: absolute;
  bottom: 65px;
  left: 0;
  right: 0;
  height: 4px;
  background: rgba(255, 255, 255, 0.15);
  cursor: pointer;
  transform: scaleY(0.7);
  transition: transform 0.2s ease, height 0.2s ease;
  overflow: hidden;
}

.progress-bar:hover {
  transform: scaleY(1);
  height: 6px;
}

.progress-filled {
  height: 100%;
  background: linear-gradient(90deg, #724bb7, #4f27cd);
  position: relative;
  transition: width 0.1s linear;
}

.progress-filled::after {
  content: '';
  position: absolute;
  right: -3px;
  top: 50%;
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.2s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.progress-bar:hover .progress-filled::after {
  transform: translateY(-50%) scale(1);
}

.big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  height: 90px;
  background: rgba(114, 75, 183, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
  box-shadow: 0 0 30px rgba(114, 75, 183, 0.5);
}

.big-play-button::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  background: linear-gradient(45deg, #724bb7, #4f27cd, #724bb7);
  z-index: -1;
  opacity: 0.5;
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.big-play-button:hover {
  background: rgba(114, 75, 183, 0.5);
  transform: translate(-50%, -50%) scale(1.1);
  box-shadow: 0 0 40px rgba(114, 75, 183, 0.7);
}

.mute-indicator {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgba(15, 15, 26, 0.7);
  border-radius: 20px;
  padding: 8px 14px;
  color: white;
  font-size: 14px;
  align-items: center;
  gap: 8px;
  z-index: 30;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.mute-indicator:hover {
  background: rgba(114, 75, 183, 0.7);
  transform: scale(1.05);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .video-outer-container {
    width: 90%;
    max-width: 400px;
    margin: 10px auto;
    border-radius: 12px;
    padding: 2px;
  }

  .video-player-container {
    border-radius: 10px;
  }

  .progress-bar {
    bottom: 25px;
  }

  .controls-overlay {
    padding: 15px 12px 12px;
    opacity: 1;
  }

  .volume-slider {
    display: none;
  }

  .mute-indicator {
    display: flex;
  }

  .control-button {
    padding: 12px;
  }

  .controls-container {
    padding: 0 8px;
  }

  .big-play-button {
    width: 70px;
    height: 70px;
  }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .video-outer-container {
    width: 85%;
    max-width: 800px;
    margin: 15px auto;
  }
}

/* Touch Device Styles */
@media (hover: none) {
  .controls-overlay {
    opacity: 1;
  }
  
  .control-button:hover {
    transform: none;
  }
  
  .big-play-button:hover {
    transform: translate(-50%, -50%);
    background: rgba(114, 75, 183, 0.3);
  }
}

/* Fullscreen Styles */
.video-player-container:fullscreen {
  width: 100vw;
  height: 100vh;
  background: #0f0f1a;
}

.video-player-container:fullscreen .video-wrapper {
  height: 100vh;
}

.video-player-container:fullscreen .video-element {
  object-fit: contain;
}
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleWaiting = () => setIsBuffering(true);
    const handlePlaying = () => {
      setIsBuffering(false);
      setIsLoading(false);
    };
    const handleLoadStart = () => setIsLoading(true);
    const handleLoadedData = () => setIsLoading(false);

    video.addEventListener('waiting', handleWaiting);
    video.addEventListener('playing', handlePlaying);
    video.addEventListener('loadstart', handleLoadStart);
    video.addEventListener('loadeddata', handleLoadedData);

    return () => {
      video.removeEventListener('waiting', handleWaiting);
      video.removeEventListener('playing', handlePlaying);
      video.removeEventListener('loadstart', handleLoadStart);
      video.removeEventListener('loadeddata', handleLoadedData);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (videoRef.current) {
              videoRef.current.play()
                .then(() => setIsPlaying(true))
                .catch(error => console.log("Play failed:", error));
            }
          } else {
            if (videoRef.current) {
              videoRef.current.pause();
              setIsPlaying(false);
            }
          }
        });
      },
      {
        threshold: 0.5
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const togglePlay = async () => {
    if (videoRef.current) {
      try {
        if (isPlaying) {
          await videoRef.current.pause();
        } else {
          await videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
      } catch (error) {
        console.error("Playback error:", error);
        // Handle the error appropriately
        if (error.name === "NotAllowedError") {
          // User needs to interact first
          setIsMuted(true);
          videoRef.current.muted = true;
          try {
            await videoRef.current.play();
            setIsPlaying(true);
          } catch (retryError) {
            console.error("Retry failed:", retryError);
          }
        }
      }
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progress);
    }
  };

  const handleProgressClick = (e) => {
    const progressBar = e.currentTarget;
    const clickPosition = (e.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth;
    if (videoRef.current) {
      videoRef.current.currentTime = clickPosition * videoRef.current.duration;
    }
  };

  const LoadingSpinner = () => (
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
  );

  return (
    <div className="video-outer-container">
      <div className="video-player-container" ref={containerRef}>
        <div className="video-wrapper">
          <video
            ref={videoRef}
            src={src}
            className="video-element"
            onClick={togglePlay}
            onTimeUpdate={handleTimeUpdate}
            muted={isMuted}
            playsInline
          />
          
          {(isLoading || isBuffering) && <LoadingSpinner />}

          <div className="mute-indicator" onClick={toggleMute}>
            {isMuted ? <VolumeX size={16} /> : <Volume2 size={16} />}
            {isMuted ? 'Unmute' : 'Mute'}
          </div>

          <div className="controls-overlay">
            <div className="progress-bar" onClick={handleProgressClick}>
              <div 
                className="progress-filled"
                style={{ width: `${progress}%` }}
              />
            </div>

            <div className="controls-container">
              <div className="controls-left">
                <button className="control-button" onClick={togglePlay}>
                  {isPlaying ? (
                    <Pause size={24} />
                  ) : (
                    <Play size={24} />
                  )}
                </button>
                
                <div className="volume-button">
                  <button className="control-button" onClick={toggleMute}>
                    {isMuted ? (
                      <VolumeX size={24} />
                    ) : (
                      <Volume2 size={24} />
                    )}
                  </button>
                  <div className="volume-slider">
                    <div className="volume-filled" style={{ width: isMuted ? '0%' : '100%' }}></div>
                  </div>
                </div>
              </div>

              <div className="controls-right">
                <button className="control-button" onClick={toggleFullscreen}>
                  <Maximize size={24} />
                </button>
              </div>
            </div>
          </div>

          {!isPlaying && !isLoading && !isBuffering && (
            <div className="big-play-button" onClick={togglePlay}>
              <Play size={32} color="white" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;