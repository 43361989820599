import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../helpers/axiosconfig';
import { toast } from 'react-toastify';
import AddressForm from '../order/addressForm';
import LoginModal from '../Signin_up/Signin';
import { useAuth } from '../Signin_up/AuthContext';
import { useCart } from '../../utils/CartContext';
import { useCartUI } from '../../utils/CartUIContext';

const calculateDiscount = (subtotal, coupon) => {
  if (!coupon) return 0;
  return coupon.type === 'percentage' 
    ? Math.round(subtotal * (coupon.discount / 100))
    : Math.round(Math.min(coupon.discount, subtotal));
};

const validateCheckoutData = (checkoutData) => {
  if (!checkoutData) return false;
  
  const requiredFields = {
    cart: Array.isArray(checkoutData.cart) && checkoutData.cart.length > 0,
    pricing: checkoutData.pricing && 
             typeof checkoutData.pricing.total === 'number' &&
             typeof checkoutData.pricing.subtotal === 'number',
    address: checkoutData.address &&
             checkoutData.address.name &&
             checkoutData.address.street &&
             checkoutData.address.city &&
             checkoutData.address.state &&
             checkoutData.address.pincode
  };
  
  return Object.values(requiredFields).every(field => field === true);
};

const createFreshCheckoutData = (cart, selectedCoupon, address) => {
  const subtotal = Math.round(cart.reduce((total, item) => total + item.price * item.quantity, 0));
  const discount = selectedCoupon ? Math.round(calculateDiscount(subtotal, selectedCoupon)) : 0;
  const total = Math.max(0, subtotal - discount);

  return {
    cart: cart.map(item => ({
      productId: item.productId,
      variationId: item.variationId,
      name: item.name,
      price: Math.round(item.price),
      quantity: item.quantity,
      imageUrl: Array.isArray(item.imageUrl) ? item.imageUrl : [item.imageUrl],
      variationName: item.variationName,
      _id: item._id
    })),
    pricing: {
      subtotal,
      discount,
      total
    },
    coupon: selectedCoupon,
    address: address
  };
};

const CartSidebar = () => {
  const { isCartOpen, closeCart } = useCartUI();
  const { cart, removeFromCart, updateQuantity, clearCart } = useCart();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cartTimer, setCartTimer] = useState(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [couponInput, setCouponInput] = useState('');
  const [isCouponSectionOpen, setIsCouponSectionOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [isClosing, setIsClosing] = useState(false);
  const [availableCoupons, setAvailableCoupons] = useState([]);
  const [loading, setLoading] = useState(true);


  const fetchCoupons = async () => {
    try {
      const response = await axios.get('/coupons');
      setAvailableCoupons(response.data.coupons);
      // setLoading(false);
    } catch (error) {
      console.error('Error fetching coupons:', error);
      toast.error('Failed to fetch coupons');
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);



  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeCart();
      setIsClosing(false);
    }, 300); // Match this with your animation duration
  };

  
  // Available (visible) coupons
  // const [availableCoupons] = useState([
  //   { id: 1, code: 'MALAK5', discount: 5, type: 'percentage', minAmount: 299 },
  //   { id: 2, code: 'MALAK10', discount: 10, type: 'percentage', minAmount: 599 },
  //   { id: 3, code: 'MALAK15', discount: 15, type: 'percentage', minAmount: 999 },
  // ]);

  // Hidden coupons (special/custom coupons)
  const hiddenCoupons = [
    { id: 4, code: 'MALAKSPEACIAL10', discount: 10, type: 'percentage', minAmount: 249 },
    { id: 5, code: 'MALAKSPEACIAL15', discount: 15, type: 'percentage', minAmount: 499 },
    { id: 6, code: 'MALAK15', discount: 15, type: 'percentage', minAmount: 999 }
  ];

  const updateRealViewportHeight = () => {
    // Get the real viewport height
    const vh = window.innerHeight;
    // Set it as a CSS custom property
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    setViewportHeight(vh);
  };

  useEffect(() => {
    // Initial height set
    updateRealViewportHeight();

    // Update height on resize and orientation change
    const events = ['resize', 'orientationchange'];
    
    const handleResize = () => {
      // Use RAF to debounce updates
      window.requestAnimationFrame(() => {
        updateRealViewportHeight();
      });
    };

    // Handle iOS chrome showing/hiding
    let lastHeight = window.innerHeight;
    const handleScroll = () => {
      const currentHeight = window.innerHeight;
      if (currentHeight !== lastHeight) {
        lastHeight = currentHeight;
        updateRealViewportHeight();
      }
    };

    // Add all event listeners
    events.forEach(event => window.addEventListener(event, handleResize));
    window.addEventListener('scroll', handleScroll);
    
    // Setup interval to check height changes
    const heightCheckInterval = setInterval(() => {
      if (window.innerHeight !== lastHeight) {
        lastHeight = window.innerHeight;
        updateRealViewportHeight();
      }
    }, 100);

    return () => {
      // Clean up all event listeners
      events.forEach(event => window.removeEventListener(event, handleResize));
      window.removeEventListener('scroll', handleScroll);
      clearInterval(heightCheckInterval);
    };
  }, []);


  useEffect(() => {
    // Only proceed if user is logged in, has mobile number, and cart has items
    if (isAuthenticated && user?.mobileNumber && cart.length > 0) {
      // Create a unique identifier for the current cart session
      const cartSessionId = `cart_session_${Date.now()}`;
      
      // Check if we've already sent a notification for this cart
      const abandonedCartNotified = localStorage.getItem('abandonedCartNotified');
      
      // Get the timestamp when the cart was first created or updated
      let cartCreatedTime = localStorage.getItem('cartCreatedTime');
      
      // If cartCreatedTime doesn't exist, create it now
      if (!cartCreatedTime) {
        cartCreatedTime = Date.now().toString();
        localStorage.setItem('cartCreatedTime', cartCreatedTime);
        localStorage.setItem('cartSessionId', cartSessionId);
      }
      
      // Clear any existing timer
      if (cartTimer) {
        clearTimeout(cartTimer);
      }
      
      // Calculate the time elapsed since cart creation
      const timeElapsed = Date.now() - parseInt(cartCreatedTime);
      const fifteenMinutesInMs = 15 * 60 * 1000; // 15 minutes in milliseconds
      
      // If notification already sent for this cart, don't set a new timer
      if (abandonedCartNotified === 'true') {
        return;
      }
      
      // If cart has been active for more than 15 minutes already, send notification immediately
      if (timeElapsed >= fifteenMinutesInMs) {
        sendAbandonmentNotification();
        return;
      }
      
      // Otherwise, set timer for remaining time until 15 minutes is reached
      const remainingTime = fifteenMinutesInMs - timeElapsed;
      
      const timer = setTimeout(() => {
        sendAbandonmentNotification();
      }, remainingTime);
      
      setCartTimer(timer);
      
      // Function to send abandonment notification
      async function sendAbandonmentNotification() {
        try {
          // Only send notification if we haven't sent one already
          if (localStorage.getItem('abandonedCartNotified') !== 'true') {
            // Get the product info from the first item in the cart
            const firstItemName = cart.length > 0 ? cart[0].name : 'your selected items';
            
            const config = {
              method: 'post',
              url: 'https://backend.aisensy.com/campaign/t1/api/v2',
              headers: { 
                'Content-Type': 'application/json'
              },
              data: {
                apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2RiMTc3ZTQ4MjFhMGJmZTQyMDhjYiIsIm5hbWUiOiJCdXltYWxhay5pbiIsImFwcE5hbWUiOiJBaVNlbnN5IiwiY2xpZW50SWQiOiI2NzEzMzRlYWQ3OTA4NjBiOTdjYTQxMTAiLCJhY3RpdmVQbGFuIjoiTk9ORSIsImlhdCI6MTczMjA5NjM3NX0.VKJNyMu9XM3l5hIxNDiHFp6nZ2tbnh0cR2A4hnz8DTM",
                campaignName: "Still_Thinking_About_it",
                destination: user.mobileNumber.startsWith('91') ? user.mobileNumber : `91${user.mobileNumber}`,
                userName: "Buymalak.in",
                templateParams: [],
                source: "new-landing-page form",
                media: {
                  "url": "https://ik.imagekit.io/srwardmxk/website_aisence_images-01-01_G_In0ngZLU.jpg",
                  "filename": "sample_media"
                },
                buttons: [],
                carouselCards: [],
                location: {},
                attributes: {},
                paramsFallbackValue: {
                  FirstName: user.username?.split(' ')[0] || "there"
                }
              }
            };
            
            const response = await axios.request(config);
            console.log('Cart abandonment notification sent:', JSON.stringify(response.data));
            
            // Mark that we've sent a notification for this cart session
            localStorage.setItem('abandonedCartNotified', 'true');
          }
        } catch (error) {
          console.error('Error sending cart abandonment notification:', error);
        }
      }
  
      // Cleanup function
      return () => {
        if (cartTimer) {
          clearTimeout(cartTimer);
        }
      };
    }
  }, [isAuthenticated, user?.mobileNumber, cart.length, cartTimer]);


  const resetCartTracking = () => {
    localStorage.removeItem('cartCreatedTime');
    localStorage.removeItem('abandonedCartNotified');
    localStorage.removeItem('cartSessionId');
    if (cartTimer) {
      clearTimeout(cartTimer);
      setCartTimer(null);
    }
  };


  useEffect(() => {
    // Function to check cart abandonment on page unload
    const handleBeforeUnload = () => {
      // If cart exists and user is authenticated
      if (isAuthenticated && user?.mobileNumber && cart.length > 0) {
        const cartCreatedTime = localStorage.getItem('cartCreatedTime');
        const abandonedCartNotified = localStorage.getItem('abandonedCartNotified');
        
        // If cart creation time exists and notification hasn't been sent yet
        if (cartCreatedTime && abandonedCartNotified !== 'true') {
          const timeElapsed = Date.now() - parseInt(cartCreatedTime);
          const fifteenMinutesInMs = 15 * 60 * 1000;
          
          // If cart has been idle for more than 15 minutes
          if (timeElapsed >= fifteenMinutesInMs) {
            // We can't reliably send the API request during page unload
            // So we set a flag to send it when the page is reopened
            localStorage.setItem('sendAbandonmentOnReload', 'true');
          }
        }
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isAuthenticated, user, cart]);


  useEffect(() => {
    const checkPendingAbandonmentNotification = async () => {
      const shouldSendAbandonment = localStorage.getItem('sendAbandonmentOnReload');
      const abandonedCartNotified = localStorage.getItem('abandonedCartNotified');
      
      if (shouldSendAbandonment === 'true' && abandonedCartNotified !== 'true' && 
          isAuthenticated && user?.mobileNumber) {
        try {
          // Send the notification
          const config = {
            method: 'post',
            url: 'https://backend.aisensy.com/campaign/t1/api/v2',
            headers: { 
              'Content-Type': 'application/json'
            },
            data: {
              apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2RiMTc3ZTQ4MjFhMGJmZTQyMDhjYiIsIm5hbWUiOiJCdXltYWxhay5pbiIsImFwcE5hbWUiOiJBaVNlbnN5IiwiY2xpZW50SWQiOiI2NzEzMzRlYWQ3OTA4NjBiOTdjYTQxMTAiLCJhY3RpdmVQbGFuIjoiTk9ORSIsImlhdCI6MTczMjA5NjM3NX0.VKJNyMu9XM3l5hIxNDiHFp6nZ2tbnh0cR2A4hnz8DTM",
              campaignName: "Still_Thinking_About_it",
              destination: user.mobileNumber.startsWith('91') ? user.mobileNumber : `91${user.mobileNumber}`,
              userName: "Buymalak.in",
              templateParams: [],
              source: "new-landing-page form",
              media: {
                "url": "https://ik.imagekit.io/srwardmxk/website_aisence_images-01-01_G_In0ngZLU.jpg",
                "filename": "sample_media"
              },
              buttons: [],
              carouselCards: [],
              location: {},
              attributes: {},
              paramsFallbackValue: {
                FirstName: user.username?.split(' ')[0] || "there"
              }
            }
          };
          
          const response = await axios.request(config);
          console.log('Pending cart abandonment notification sent:', JSON.stringify(response.data));
          
          // Mark that we've sent a notification
          localStorage.setItem('abandonedCartNotified', 'true');
          localStorage.removeItem('sendAbandonmentOnReload');
        } catch (error) {
          console.error('Error sending pending cart abandonment notification:', error);
        }
      }
    };
    
    // Check for pending notification when component mounts and user is available
    if (isAuthenticated && user?.mobileNumber) {
      checkPendingAbandonmentNotification();
    }
  }, [isAuthenticated, user?.mobileNumber]);

  useEffect(() => {
    const loadCartFromStorage = () => {
      try {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      } catch (error) {
        console.error('Error loading cart from storage:', error);
      }
    };

    loadCartFromStorage();
  }, []); 

  const updateLocalStorage = (updatedCart) => {
    try {
      localStorage.setItem('cart', JSON.stringify(updatedCart));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
  };

  useEffect(() => {
    const loadRazorpay = async () => {
      if (document.querySelector('script[src="https://checkout.razorpay.com/v1/magic-checkout.js"]')) {
        setRazorpayLoaded(true);
        return;
      }
  
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/magic-checkout.js';
      script.async = true;
      script.onload = () => setRazorpayLoaded(true);
      document.body.appendChild(script);
    };
  
    loadRazorpay();
  }, []);

  const syncCartWithLocalStorage = () => {
    try {
      // This function should now only read from localStorage, not write to it
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      return storedCart;
    } catch (error) {
      console.error('Error syncing cart:', error);
      return [];
    }
  };

  useEffect(() => {
    syncCartWithLocalStorage();
  }, []);

  

   const handleLoginSuccess = async (response) => {
    try {
      const checkoutData = createFreshCheckoutData(cart, selectedCoupon, null);
      localStorage.setItem('checkoutData', JSON.stringify(checkoutData));
      
      setShowLoginModal(false);
      setShowAddressForm(true);
      closeCart();
    } catch (error) {
      console.error('Error handling login success:', error);
      toast.error('Error processing checkout. Please try again.');
    }
  };

  

  

  useEffect(() => {
    if (isAuthenticated) {
      try {
        const storedCheckoutData = JSON.parse(localStorage.getItem('checkoutData'));
        
        // Restore coupon if it existed
        if (storedCheckoutData?.coupon) {
          setSelectedCoupon(storedCheckoutData.coupon);
        }
      } catch (error) {
        console.error('Error restoring cart data after login:', error);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isCartOpen) {
      setShowAddressForm(false);
      setShowLoginModal(false);
    }
  }, [isCartOpen]);

  

  useEffect(() => {
    // Load cart from localStorage when component mounts
    const loadInitialCart = () => {
      try {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        if (storedCart.length > 0) {
          // Instead of trying to add items one by one, just update the localStorage
          localStorage.setItem('cart', JSON.stringify(storedCart));
        }
      } catch (error) {
        console.error('Error loading initial cart:', error);
      }
    };
  
    loadInitialCart();
  }, []); 

  const validateCoupon = (code, cartTotal) => {
    // Check both available and hidden coupons
    const coupon = [...availableCoupons, ...hiddenCoupons].find(
      c => c.code === code.toUpperCase()
    );
    
    if (!coupon) {
      return { valid: false, message: 'Invalid coupon code' };
    }

    if (coupon.minAmount && cartTotal < coupon.minAmount) {
      return { 
        valid: false, 
        message: `This coupon requires a minimum purchase of ₹${coupon.minAmount}`
      };
    }

    return { valid: true, coupon };
  };

  const handleCouponSubmit = (e) => {
    e.preventDefault();
    
    if (!couponInput.trim()) {
      toast.error('Please enter a coupon code');
      return;
    }

    if (cart.length === 0) {
      toast.error('Please add products to your cart before applying a coupon');
      return;
    }

    const cartTotal = getSubtotal();
    const { valid, message, coupon } = validateCoupon(couponInput, cartTotal);

    if (valid) {
      setSelectedCoupon(coupon);
      setCouponInput('');
      toast.success(`Coupon ${coupon.code} applied successfully!`);
    } else {
      toast.error(message);
    }
  };

  const applyCoupon = (coupon) => {
    if (cart.length === 0) {
      toast.error('Please add products to your cart before applying a coupon');
      return;
    }
  
    const cartTotal = getSubtotal();
    const isValid = validateExistingCoupon(cartTotal, coupon);
  
    if (!isValid) {
      const amountNeeded = coupon.minAmount - cartTotal;
      toast.error(
        `Please add products worth ₹${Math.round(amountNeeded).toLocaleString('en-IN')} more to use coupon ${coupon.code}. Minimum order value required is ₹${coupon.minAmount.toLocaleString('en-IN')}.`
      );
      return;
    }
  
    const savings = calculateDiscount(cartTotal, coupon);
    setSelectedCoupon(coupon);
    toast.success(
      `Coupon ${coupon.code} applied successfully! You saved ₹${Math.round(savings).toLocaleString('en-IN')}`,
      { duration: 3000 }
    );
  };

  const validateExistingCoupon = (cartTotal, coupon) => {
    if (!coupon) return true;
    return cartTotal >= coupon.minAmount;
  };

  useEffect(() => {
    if (selectedCoupon && cart.length > 0) {
      const currentTotal = getSubtotal();
      const isValidCoupon = validateExistingCoupon(currentTotal, selectedCoupon);
      
      if (!isValidCoupon) {
        toast.info(`Coupon ${selectedCoupon.code} removed as cart total is below minimum required amount of ₹${selectedCoupon.minAmount}`);
        setSelectedCoupon(null);
      }
    }
  }, [cart, selectedCoupon]);

  const removeCoupon = () => {
    setSelectedCoupon(null);
    toast.info('Coupon removed');
  };

  const getSubtotal = () => {
    if (cart.length === 0) return 0;
    return Math.round(cart.reduce((total, item) => total + item.price * item.quantity, 0));
  };
  
  const getDiscountAmount = () => {
    if (!selectedCoupon || cart.length === 0) return 0;
    const subtotal = getSubtotal();
    
    if (selectedCoupon.type === 'percentage') {
      return Math.round(subtotal * (selectedCoupon.discount / 100));
    } else {
      return Math.round(Math.min(selectedCoupon.discount, subtotal));
    }
  };
  
  const getTotalPrice = () => {
    if (cart.length === 0) return "0";
    const subtotal = getSubtotal();
    const discount = getDiscountAmount();
    return Math.max(0, subtotal - discount); // Remove .toFixed(2)
  };


  const handleAddressSubmit = async (selectedAddress) => {
    if (!selectedAddress || !selectedAddress.fullName) {
      toast.error('Please select a valid address');
      return;
    }
    closeCart(); // Close cart before navigation
  
    try {
      // Get current cart state
      const currentCart = JSON.parse(localStorage.getItem('cart')) || [];
      if (currentCart.length === 0) {
        throw new Error('Cart is empty');
      }
  
      // Calculate totals
      const subtotal = currentCart.reduce((total, item) => total + (item.price * item.quantity), 0);
      const discount = selectedCoupon ? calculateDiscount(subtotal, selectedCoupon) : 0;
      const total = Math.max(0, subtotal - discount);
  
      // Format address
      const formattedAddress = {
        name: selectedAddress.fullName,
        street: selectedAddress.address1 + (selectedAddress.address2 ? ', ' + selectedAddress.address2 : ''),
        city: selectedAddress.city,
        state: selectedAddress.state,
        pincode: selectedAddress.pincode,
        phone: selectedAddress.phone,
        email: selectedAddress.email,
        type: selectedAddress.type
      };
  
      // Create checkout data
      const checkoutData = {
        cart: currentCart.map(item => ({
          productId: item.productId,
          variationId: item.variationId,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
          imageUrl: Array.isArray(item.imageUrl) ? item.imageUrl : [item.imageUrl],
          variationName: item.variationName,
          _id: item._id
        })),
        pricing: {
          subtotal,
          discount,
          total
        },
        coupon: selectedCoupon,
        address: formattedAddress
      };
  
      // Validate checkout data
      const isValid = checkoutData.cart?.length > 0 && 
                     checkoutData.pricing?.total > 0 && 
                     checkoutData.address?.name && 
                     checkoutData.address?.street;
  
      if (!isValid) {
        throw new Error('Invalid checkout data');
      }
  
      // Save and proceed
      localStorage.setItem('checkoutData', JSON.stringify(checkoutData));
      navigate('/checkout/address');
    } catch (error) {
      console.error('Error processing address submission:', error);
      toast.error('Error processing checkout. Please try again.');
    }
  };

  const validateCheckoutData = (checkoutData) => {
    if (!checkoutData) return false;
    
    const requiredFields = {
      cart: Array.isArray(checkoutData.cart) && checkoutData.cart.length > 0,
      pricing: checkoutData.pricing && 
               typeof checkoutData.pricing.total === 'number' &&
               typeof checkoutData.pricing.subtotal === 'number',
      address: checkoutData.address &&
               checkoutData.address.name &&
               checkoutData.address.street &&
               checkoutData.address.city &&
               checkoutData.address.state &&
               checkoutData.address.pincode
    };
    
    return Object.values(requiredFields).every(field => field === true);
  };

  const refreshCheckoutData = (cart, selectedCoupon, address) => {
    const subtotal = cart.reduce((total, item) => total + item.price * item.quantity, 0);
    const discount = selectedCoupon ? calculateDiscount(subtotal, selectedCoupon) : 0;
    const total = Math.max(0, subtotal - discount);
  
    return {
      cart: cart.map(item => ({
        productId: item.productId,
        variationId: item.variationId,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        imageUrl: Array.isArray(item.imageUrl) ? item.imageUrl : [item.imageUrl],
        variationName: item.variationName,
        _id: item._id
      })),
      pricing: {
        subtotal,
        discount,
        total
      },
      coupon: selectedCoupon,
      address: address
    };
  };

  const checkAuthAndProceed = () => {
    if (!isAuthenticated) {
      // First close the cart
      closeCart();
      // Wait a short moment for the cart closing animation to complete
      setTimeout(() => {
        setShowLoginModal(true);
      }, 300);
    } else {
      setShowAddressForm(true);  // This is where we need to make the change
    }
  };

  return (
    <>
      {isCartOpen && <div className="cart-sidebar-overlay" onClick={closeCart}></div>}

      <div 
        className={`cart-sidebar ${isCartOpen ? 'open' : ''} ${isClosing ? 'closing' : ''}`}
        style={{ 
          height: `${viewportHeight}px`,
          maxHeight: `${viewportHeight}px`
        }}
      >
        {showAddressForm && isAuthenticated ? (
          <AddressForm 
            onSubmit={handleAddressSubmit}
            onClose={() => setShowAddressForm(false)}
            existingAddresses={[]}
          />
        ) : (
          <>
            <div className="cart-sidebar-header">
              <h2>Your Cart</h2>
              <button 
                onClick={closeCart} 
                className="close-btn"
                aria-label="Close cart"
              >✕</button>
            </div>

            <div className="cart-sidebar-items">
              {cart.length === 0 ? (
                <div className="empty-cart">
                  <span className="cart-icon">🛒</span>
                  <p>Your cart is empty</p>
                </div>
              ) : (
                cart.map((item) => (
                  <div key={`${item._id}-${item.variationId || ''}`} className="cart-item">
                    <img 
                      src={item.imageUrl && item.imageUrl[0]} 
                      alt={item.name} 
                      className="item-image"
                      onError={(e) => {
                        e.target.src = '/placeholder-image.jpg';
                      }}
                    />
                    <div className="item-details">
                      <h3>{item.name}</h3>
                      {item.variationName && (
                        <p className="variation-name">{item.variationName}</p>
                      )}
                      <p className="item-price">₹{Math.round(item.price).toLocaleString('en-IN')}</p>
                      <div className="quantity-control">
                        <button 
                          onClick={() => updateQuantity(item.productId, item.variationId, -1)}
                          aria-label="Decrease quantity"
                        >-</button>
                        <span>{item.quantity}</span>
                        <button 
                          onClick={() => updateQuantity(item.productId, item.variationId, 1)}
                          aria-label="Increase quantity"
                        >+</button>
                      </div>
                    </div>
                    <button 
                      onClick={() => removeFromCart(item.productId, item.variationId)}
                      className="remove-btn"
                      aria-label={`Remove ${item.name} from cart`}
                    >
                      🗑️
                    </button>
                  </div>
                ))
              )}
            </div>

            <div className="cart-sidebar-coupons">
  {/* Coupon Section Header */}
  <button 
    onClick={() => setIsCouponSectionOpen(!isCouponSectionOpen)}
    className="coupon-section-toggle"
  >
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center gap-2">
        <span className="text-lg font-semibold">Coupons & Discounts</span>
        {selectedCoupon && (
          <span className="applied-tag">1 Applied</span>
        )}
      </div>
      <span className={`toggle-icon ${isCouponSectionOpen ? 'open' : ''}`}>
        {isCouponSectionOpen ? '−' : '+'}
      </span>
    </div>
  </button>

  {/* Expandable Content */}
  <div className={`coupon-content ${isCouponSectionOpen ? 'open' : ''}`}>
    {/* Available Coupons */}
    <div className="available-coupons">
      <h3>Available Coupons</h3>
      {cart.length === 0 ? (
        <p className="empty-cart-message">Add products to use coupons</p>
      ) : (
        <div className="coupon-grid">
  {availableCoupons.map((coupon) => (
  <button
    key={coupon.id}
    onClick={() => applyCoupon(coupon)} // Ensure this is correct
    className={`coupon-btn ${selectedCoupon?.id === coupon.id ? 'active' : ''}`}
  >
    {coupon.code}
    <span>
      {coupon.type === 'percentage' 
        ? `${coupon.discount}% off` 
        : `₹${coupon.discount} off`}
    </span>
    <small>Min. order ₹{coupon.minAmount}</small>
  </button>
))}
</div>
      )}
    </div>

    {/* Custom Coupon Form */}
    <div className="custom-coupon-section">
      <h3>Have a coupon code?</h3>
      <form onSubmit={handleCouponSubmit} className="coupon-form">
        <input
          type="text"
          value={couponInput}
          onChange={(e) => setCouponInput(e.target.value.toUpperCase())}
          placeholder="Enter code"
          className="coupon-input"
        />
        <button type="submit" className="apply-btn">
          Apply
        </button>
      </form>
    </div>
  </div>

  {/* Applied Coupon Display - Always visible */}
  {selectedCoupon && (
    <div className="applied-coupon">
      <div className="coupon-info">
        <span className="coupon-label">Applied: {selectedCoupon.code}</span>
        <span className="discount-amount">
          {selectedCoupon.type === 'percentage' 
            ? `${selectedCoupon.discount}% off`
            : `₹${selectedCoupon.discount} off`}
        </span>
      </div>
      <button 
        onClick={removeCoupon}
        className="remove-coupon-btn"
        aria-label="Remove coupon"
      >
        ✕
      </button>
    </div>
  )}
</div>

          {/* Cart Footer with Totals */}
          <div className="cart-sidebar-footer mt-4">
  <div className="subtotal flex justify-between py-2">
    <span>Subtotal:</span>
    <span>₹{Math.round(getSubtotal()).toLocaleString('en-IN')}</span>
  </div>
  {selectedCoupon && (
    <div className="discount flex justify-between py-2 text-green-600">
      <span>Discount:</span>
      <span>-₹{Math.round(getDiscountAmount()).toLocaleString('en-IN')}</span>
    </div>
  )}
  <div className="total flex justify-between py-2 font-bold text-lg">
    <span>Total:</span>
    <span>₹{Math.round(parseFloat(getTotalPrice())).toLocaleString('en-IN')}</span>
  </div>

            <button
              className="checkout-btn w-full mt-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={checkAuthAndProceed}
              disabled={cart.length === 0 || isProcessing}
              aria-label={isProcessing ? 'Processing checkout' : 'Proceed to checkout'}
            >
              {isProcessing ? 'Processing...' : 'Proceed to Checkout'}
            </button>
          </div>
        </>
      )}
    </div>

    <LoginModal
      isOpen={showLoginModal}
      onClose={() => setShowLoginModal(false)}
      onLoginSuccess={handleLoginSuccess}
    />
  </>
);
};

export default CartSidebar;