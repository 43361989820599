// AdminProducts.jsx
import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash2, X, Save, Upload } from 'lucide-react';
import '../../styles/AdminProducts.scss';
import instance from '../../helpers/axiosconfig';
import toast from 'react-hot-toast';

const AdminProducts = () => {
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const CATEGORIES = {
    LAUNDRY_CARE: 'LAUNDRY CARE',
    KITCHEN_CARE: 'KITCHEN CARE',
    BATHROOM_CARE: 'BATHROOM CARE',
    SURFACE_CARE: 'SURFACE CARE'
  };

  const VOLUMES = {
    ML_900: '900 ML',
    ML_900_PACK_2: '900 ML (Pack of 2)',
    ML_900_PACK_3: '900 ML (Pack of 3)',
    LITRE_1: '1 LITRE',
    LITRE_1_8: '1.8 LITRE',
    LITRE_5: '5 LITRE'
  };

  const FRAGRANCES = {
    LEMON: 'Lemon',
    LAVENDER: 'Lavender',
    JASMINE: 'Jasmine',
    ROSE: 'Rose',
    NaN: 'Null'
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const response = await instance.get('products');
      setProducts(response.data);
    } catch (err) {
      setError('Failed to fetch products: ' + (err.response?.data?.message || err.message));
      toast.error('Failed to fetch products');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (files, variationIndex, formData, setFormData) => {
    try {
      const formDataObj = new FormData();
      Array.from(files).forEach(file => {
        formDataObj.append('images', file);
      });

      const response = await instance.post('update-image', formDataObj, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Update the specific variation's imageUrls
      const newVariations = [...formData.variations];
      newVariations[variationIndex] = {
        ...newVariations[variationIndex],
        imageUrls: response.data.filenames
      };
      setFormData({ ...formData, variations: newVariations });

      return response.data.filenames;
    } catch (err) {
      toast.error('Failed to upload images');
      throw new Error('Failed to upload images: ' + (err.response?.data?.message || err.message));
    }
  };

  const handleSubmit = async (product) => {
    try {
      if (product._id) {
        await instance.put(`products/${product._id}`, product);
        toast.success('Product updated successfully');
      } else {
        await instance.post('products', product);
        toast.success('Product created successfully');
      }

      setSuccess('Product saved successfully');
      fetchProducts();
      setIsAddingNew(false);
      setEditingProduct(null);
    } catch (err) {
      const errorMsg = 'Failed to save product: ' + (err.response?.data?.message || err.message);
      setError(errorMsg);
      toast.error(errorMsg);
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await instance.delete(`products/${productId}`);
        toast.success('Product deleted successfully');
        fetchProducts();
      } catch (err) {
        const errorMsg = 'Failed to delete product: ' + (err.response?.data?.message || err.message);
        setError(errorMsg);
        toast.error(errorMsg);
      }
    }
  };

  const ProductForm = ({ product, onSubmit, onCancel }) => {
    const [formData, setFormData] = useState(product || {
      category: '',
      baseDescription: '',
      variations: [{
        name: '',
        description: '',
        price: 0,
        stock: 0,
        volume: '',
        fragrance: 'Null',
        imageUrls: [],
        descriptionImages: [],
        quantity: 1,
        discount: 0
      }]
    });

    const addVariation = () => {
      setFormData({
        ...formData,
        variations: [...formData.variations, {
          name: '',
          description: '',
          price: 0,
          stock: 0,
          volume: '',
          fragrance: 'Null',
          imageUrls: [],
          descriptionImages: [],
          quantity: 1,
          discount: 0
        }]
      });
    };

    const removeVariation = (index) => {
      if (formData.variations.length > 1) {
        const newVariations = formData.variations.filter((_, i) => i !== index);
        setFormData({
          ...formData,
          variations: newVariations
        });
      }
    };

    const handleChange = (e, field, variationIndex) => {
      const value = e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value;
      
      if (variationIndex !== undefined) {
        const newVariations = [...formData.variations];
        newVariations[variationIndex] = {
          ...newVariations[variationIndex],
          [field]: value
        };
        setFormData({ ...formData, variations: newVariations });
      } else {
        setFormData({ ...formData, [field]: value });
      }
    };

    return (
      <form className="product-form" onSubmit={(e) => {
        e.preventDefault();
        onSubmit(formData);
      }}>
        <div className="form-group">
          <label>Category</label>
          <select
            value={formData.category}
            onChange={(e) => handleChange(e, 'category')}
            required
          >
            <option value="">Select Category</option>
            {Object.values(CATEGORIES).map(cat => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Base Description</label>
          <textarea
            value={formData.baseDescription}
            onChange={(e) => handleChange(e, 'baseDescription')}
            required
          />
        </div>

        <div className="variations-container">
          {formData.variations.map((variation, index) => (
            <div key={index} className="variation-section">
              <div className="variation-header">
                <h3>Variation {index + 1}</h3>
                {formData.variations.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeVariation(index)}
                    className="btn-icon btn-icon--danger"
                  >
                    <Trash2 size={20} />
                  </button>
                )}
              </div>

              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  value={variation.name}
                  onChange={(e) => handleChange(e, 'name', index)}
                  required
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  value={variation.description}
                  onChange={(e) => handleChange(e, 'description', index)}
                  required
                />
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>Price</label>
                  <input
                    type="number"
                    value={variation.price}
                    onChange={(e) => handleChange(e, 'price', index)}
                    min="0"
                    step="0.01"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Stock</label>
                  <input
                    type="number"
                    value={variation.stock}
                    onChange={(e) => handleChange(e, 'stock', index)}
                    min="0"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>Volume</label>
                  <select
                    value={variation.volume}
                    onChange={(e) => handleChange(e, 'volume', index)}
                    required
                  >
                    <option value="">Select Volume</option>
                    {Object.values(VOLUMES).map(vol => (
                      <option key={vol} value={vol}>{vol}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Fragrance</label>
                  <select
                    value={variation.fragrance}
                    onChange={(e) => handleChange(e, 'fragrance', index)}
                  >
                    {Object.values(FRAGRANCES).map(frag => (
                      <option key={frag} value={frag}>{frag}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label>Images</label>
                <div className="file-input-wrapper">
                  <input
                    type="file"
                    multiple
                    onChange={(e) => handleFileUpload(e.target.files, index, formData, setFormData)}
                    accept="image/*"
                  />
                  <Upload size={20} />
                  <span>Choose Files</span>
                </div>
                {variation.imageUrls.length > 0 && (
                  <div className="image-preview">
                    {variation.imageUrls.map((url, i) => (
                      <div key={i} className="image-preview-item">
                        <img src={url} alt={`Preview ${i + 1}`} />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>Quantity</label>
                  <input
                    type="number"
                    value={variation.quantity}
                    onChange={(e) => handleChange(e, 'quantity', index)}
                    min="1"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Discount (%)</label>
                  <input
                    type="number"
                    value={variation.discount}
                    onChange={(e) => handleChange(e, 'discount', index)}
                    min="0"
                    max="100"
                    step="0.01"
                  />
                </div>
              </div>
            </div>
          ))}

          <button
            type="button"
            onClick={addVariation}
            className="btn-secondary variation-add-btn"
          >
            <Plus size={20} />
            Add Variation
          </button>
        </div>

        <div className="form-actions">
          <button type="button" onClick={onCancel} className="btn-secondary">
            <X size={20} />
            Cancel
          </button>
          <button type="submit" className="btn-primary">
            <Save size={20} />
            Save
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className="admin-products">
      <div className="admin-products__header">
        <h2>Product Management</h2>
        {!isAddingNew && !editingProduct && (
          <button onClick={() => setIsAddingNew(true)} className="btn-primary">
            <Plus size={20} />
            Add New Product
          </button>
        )}
      </div>

      {error && <div className="alert alert-error">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}

      {(isAddingNew || editingProduct) ? (
        <ProductForm
          product={editingProduct}
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsAddingNew(false);
            setEditingProduct(null);
          }}
        />
      ) : isLoading ? (
        <div className="loading-spinner">
          <div className="spinner"></div>
          <p>Loading products...</p>
        </div>
      ) : (
        <div className="products-list">
          {products.map(product => (
            <div key={product._id} className="product-card">
              <div className="product-card__content">
                <h3>{product.variations[0].name}</h3>
                <p className="category">{product.category}</p>
                <p className="variations-count">
                  {product.variations.length} variation{product.variations.length > 1 ? 's' : ''}
                </p>
                <p className="price">
                  From ₹{Math.min(...product.variations.map(v => v.price))}
                </p>
              </div>
              <div className="product-card__actions">
                <button
                  onClick={() => setEditingProduct(product)}
                  className="btn-icon"
                  title="Edit product"
                >
                  <Edit size={20} />
                </button>
                <button
                  onClick={() => handleDelete(product._id)}
                  className="btn-icon btn-icon--danger"
                  title="Delete product"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminProducts;