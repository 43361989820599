import React, { useState, useEffect } from 'react';
import Footer from './HomePageComponents/Footer';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import FilterComponent from './ProductPageComponents/FilterComponent';
import ProductCard from './ProductPageComponents/ProductCard';
import '../styles/ProductPage.scss';
import instance from '../helpers/axiosconfig';
import CartSidebar from './CartPages/CartPage';
import toast from 'react-hot-toast';

const Modal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 99999
      }}
      onClick={onClose}
    >
      <div 
        style={{
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1)',
          maxWidth: '400px',
          width: '90%',
          margin: '20px'
        }}
        onClick={e => e.stopPropagation()}
      >
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '-12px',
            right: '-12px',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: 'white',
            border: '1px solid #e2e8f0',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            color: '#4a5568',
            zIndex: 1
          }}
        >
          ×
        </button>

        <div style={{ padding: '4px' }}>
          <img 
            src="https://ik.imagekit.io/iafgy06va/offer.png?updatedAt=1737543261986"
            alt="Special Offer"
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '70vh',
              objectFit: 'contain',
              borderRadius: '4px'
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ModalContainer = ({ children }) => {
  const modalRoot = document.getElementById('modal-root') || document.createElement('div');
  
  useEffect(() => {
    if (!document.getElementById('modal-root')) {
      modalRoot.id = 'modal-root';
      document.body.appendChild(modalRoot);
    }
    
    return () => {
      if (modalRoot.parentElement) {
        modalRoot.parentElement.removeChild(modalRoot);
      }
    };
  }, [modalRoot]);

  return createPortal(children, modalRoot);
};

function ProductPage() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('price-asc');
  const [filterCategory, setFilterCategory] = useState('All Products');
  const [searchTerm, setSearchTerm] = useState('');
  const [priceRange, setPriceRange] = useState(2000);
  const [isCartOpen, setIsCartOpen] = useState(false);  
  const [cart, setCart] = useState([]);
  const [volumeRange, setVolumeRange] = useState('All');
  const [fragrance, setFragrance] = useState('All');
  const [availability, setAvailability] = useState('All');
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState('All Products');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);

    const checkAndShowPopup = () => {
      const lastVisited = localStorage.getItem('lastVisitTime');
      const currentTime = new Date().getTime();
      
      // If never visited or if 2 days have passed
      if (!lastVisited || (currentTime - parseInt(lastVisited)) > (2 * 24 * 60 * 60 * 1000)) {
        setIsModalOpen(true);
        localStorage.setItem('lastVisitTime', currentTime.toString());
      }
    };

    const modalTimer = setTimeout(() => {
      checkAndShowPopup();
    }, 1000);

    return () => clearTimeout(modalTimer);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const categoryFromURL = params.get('category');
    
    if (categoryFromURL) {
      const decodedCategory = decodeURIComponent(categoryFromURL);
      setFilterCategory(decodedCategory);
      setPageTitle(decodedCategory);
    } else {
      setFilterCategory('All Products');
      setPageTitle('All Products');
    }
  }, [window.location.search]);

  useEffect(() => {
    setPageTitle(filterCategory);
  }, [filterCategory]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await instance.get('/products');
      
      const processedProducts = [];
      response.data.forEach(product => {
        product.variations.forEach(variation => {
          processedProducts.push({
            _id: product._id,
            category: product.category,
            baseDescription: product.baseDescription,
            variations: [variation],
          });
        });
      });
      
      setProducts(processedProducts);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch products. Please try again later.');
      setLoading(false);
      console.error('Error fetching products:', err);
    }
  };

  const doesProductMatchFilters = (product) => {
    if (!product || !product.variations || !product.variations[0]) {
      return false;
    }

    const variation = product.variations[0];
    
    const isDefaultFilters = 
      filterCategory === 'All Products' && 
      priceRange === 2000 && 
      volumeRange === 'All' &&
      fragrance === 'All' &&
      availability === 'All' && 
      !searchTerm;

    if (isDefaultFilters) {
      return true;
    }

    const productCategory = product.category?.toLowerCase().trim() || '';
    const filterCategoryLower = filterCategory.toLowerCase().trim();
    
    const matchesCategory = 
      filterCategory === 'All Products' || 
      productCategory.includes(filterCategoryLower) || 
      filterCategoryLower.includes(productCategory);

    const searchTermLower = searchTerm.toLowerCase().trim();
    const matchesSearch = !searchTerm || 
      variation.name.toLowerCase().includes(searchTermLower) ||
      productCategory.includes(searchTermLower) ||
      variation.fragrance?.toLowerCase().includes(searchTermLower) ||
      variation.volume?.toLowerCase().includes(searchTermLower);

    const discountedPrice = variation.price * (1 - (variation.discount || 0) / 100);
    const matchesPrice = discountedPrice <= priceRange;

    const matchesVolume = 
      volumeRange === 'All' || 
      variation.volume === volumeRange;

    const matchesFragrance = 
      fragrance === 'All' || 
      variation.fragrance?.toLowerCase() === fragrance.toLowerCase();

    const matchesAvailability = 
      availability === 'All' || 
      (availability === 'In Stock' && variation.stock > 0) ||
      (availability === 'Out of Stock' && variation.stock === 0);

    return matchesCategory && 
           matchesSearch && 
           matchesPrice && 
           matchesVolume && 
           matchesFragrance && 
           matchesAvailability;
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const clearAllFilters = () => {
    setFilterCategory('All Products');
    setSearchTerm('');
    setPriceRange(2000);
    setVolumeRange('All');
    setFragrance('All');
    setAvailability('All');
    navigate('/products', { replace: true });
  };

  const getFilterOptions = () => {
    const volumes = new Set(['All']);
    const fragrances = new Set(['All']);
    const categories = new Set(['All Products']);
    
    products.forEach(product => {
      const variation = product.variations[0];
      if (variation.volume) volumes.add(variation.volume);
      if (variation.fragrance) fragrances.add(variation.fragrance);
      if (product.category) categories.add(product.category);
    });

    return {
      volumes: Array.from(volumes).sort(),
      fragrances: Array.from(fragrances).sort(),
      categories: Array.from(categories).sort()
    };
  };

  const sortProducts = (products) => {
    return [...products].sort((a, b) => {
      const aVariation = a.variations[0];
      const bVariation = b.variations[0];
      
      if (sortBy === 'name') {
        return aVariation.name.localeCompare(bVariation.name);
      }
      
      const aPrice = aVariation.price * (1 - (aVariation.discount || 0) / 100);
      const bPrice = bVariation.price * (1 - (bVariation.discount || 0) / 100);
      
      if (sortBy === 'price-asc') {
        return aPrice - bPrice;
      }
      if (sortBy === 'price-desc') {
        return bPrice - aPrice;
      }
      return 0;
    });
  };

  const addToCart = (cartItem) => {
    const existingItemIndex = cart.findIndex(item => 
      item.productId === cartItem.productId && 
      item.variationId === cartItem.variationId
    );
    
    let updatedCart;
    if (existingItemIndex > -1) {
      updatedCart = cart.map((item, index) => 
        index === existingItemIndex 
          ? { ...item, quantity: item.quantity + cartItem.quantity }
          : item
      );
    } else {
      updatedCart = [...cart, cartItem];
    }
    
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    
    toast.success(`${cartItem.name} added to cart`, {
      icon: '🛒'
    });
  
    window.dispatchEvent(new CustomEvent('cartUpdate', {
      detail: { cart: updatedCart }
    }));
  };

  const handleProductClick = (productId, variationId) => {
    navigate(`/product/${productId}`, { 
      state: { selectedVariationId: variationId }
    });
  };

  const toggleMobileFilter = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
    document.body.style.overflow = !isMobileFilterOpen ? 'hidden' : 'auto';
  };

  const updateCategoryAndURL = (category) => {
    setFilterCategory(category);
    setPageTitle(category);
    
    const params = new URLSearchParams(window.location.search);
    if (category === 'All Products') {
      params.delete('category');
    } else {
      params.set('category', category);
    }
    navigate(`/products?${params.toString()}`, { replace: true });
  };

  const filteredProducts = products.filter(doesProductMatchFilters);
  const filteredAndSortedProducts = sortProducts(filteredProducts);
  const filterOptions = getFilterOptions();

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <>
      <ModalContainer>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </ModalContainer>

    <div className="product-page">
      
      
      <header className="header">
        <h1>{pageTitle}</h1>
        <button 
          className="mobile-filter-toggle" 
          onClick={toggleMobileFilter}
          aria-label={isMobileFilterOpen ? "Close filters" : "Open filters"}
        >
          {isMobileFilterOpen ? '✕' : '☰'}
        </button>
      </header>
      
      <main className="main-content">
        <div className={`filter-container ${isMobileFilterOpen ? 'mobile-open' : ''}`}>
          <FilterComponent
            filterCategory={filterCategory}
            setFilterCategory={updateCategoryAndURL}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            volumeRange={volumeRange}
            setVolumeRange={setVolumeRange}
            fragrance={fragrance}
            setFragrance={setFragrance}
            availability={availability}
            setAvailability={setAvailability}
            filterOptions={filterOptions}
            isMobileFilterOpen={isMobileFilterOpen}
            onClose={() => setIsMobileFilterOpen(false)}
            onSearchClear={clearSearch}
            onClearAll={clearAllFilters}
          />
        </div>
        
        <div className="product-area">
          <div className="search-sort">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                aria-label="Search products"
              />
              {searchTerm && (
                <button 
                  className="clear-search"
                  onClick={clearSearch}
                  aria-label="Clear search"
                >
                  ✕
                </button>
              )}
              <span className="search-icon" aria-hidden="true">🔍</span>
            </div>
            <div className="sort-dropdown">
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                aria-label="Sort products"
              >
                <option value="name">Name</option>
                <option value="price-asc">Price: Low to High</option>
                <option value="price-desc">Price: High to Low</option>
              </select>
            </div>
          </div>
          
          <div className="product-grid">
            {filteredAndSortedProducts.length === 0 ? (
              <div className="no-products">
                <p>No products found matching your criteria.</p>
                <button 
                  onClick={clearAllFilters}
                  className="clear-filters-btn"
                >
                  Clear all filters
                </button>
              </div>
            ) : (
              filteredAndSortedProducts.map((product) => (
                <ProductCard
                  key={`${product._id}-${product.variations[0]._id}`}
                  product={product}
                  onAddToCart={addToCart}
                  onProductClick={handleProductClick}
                  onOpenCart={() => setIsCartOpen(true)}
                />
              ))
            )}
          </div>
        </div>
      </main>
      
      <Footer />
      
      <CartSidebar 
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        cart={cart}
        setCart={setCart}
      />
      
      {isMobileFilterOpen && (
        <div 
          className="mobile-overlay" 
          onClick={toggleMobileFilter}
          aria-hidden="true"
        />
      )}
    </div>
    </>
  );
}

export default ProductPage;