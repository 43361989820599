// CustomerAttractionStrip.jsx
import React, { useState, useEffect, useRef } from 'react';
import '../../styles/CustomerAttractionStrip.scss';

const CustomerAttractionStrip = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animation, setAnimation] = useState('fadeIn');
  const stripRef = useRef(null);

  const benefits = [
    {
      text: "EXTRA 15% OFF ON PREPAID ORDERS",
      icon: "✨",
      highlight: "15% OFF"
    },
    {
      text: "FREE EXPRESS SHIPPING ON ALL ORDERS",
      icon: "🚚",
      highlight: "FREE EXPRESS SHIPPING"
    },
    {
      text: "30-DAY MONEY BACK GUARANTEE",
      icon: "🛡️",
      highlight: "MONEY BACK GUARANTEE"
    },
    {
      text: "EXCLUSIVE LIMITED COLLECTIONS AVAILABLE",
      icon: "🎁",
      highlight: "LIMITED COLLECTIONS"
    }
  ];

  // Handle message transitions
  useEffect(() => {
    const interval = setInterval(() => {
      // First fade out current message
      setAnimation('fadeOut');
      
      // After animation completes, change message and fade in
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % benefits.length);
        setAnimation('fadeIn');
      }, 600);
    }, 4500);

    return () => clearInterval(interval);
  }, []);

  // Interactive hover effect
  useEffect(() => {
    const strip = stripRef.current;
    if (!strip) return;

    const handleMouseMove = (e) => {
      const rect = strip.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      // Calculate position for the highlight effect
      const xPercent = Math.floor((x / rect.width) * 100);
      const yPercent = Math.floor((y / rect.height) * 100);
      
      strip.style.setProperty('--mouse-x', `${xPercent}%`);
      strip.style.setProperty('--mouse-y', `${yPercent}%`);
    };

    strip.addEventListener('mousemove', handleMouseMove);
    return () => {
      strip.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Generate particles for background effect
  const generateParticles = () => {
    const particles = [];
    for (let i = 0; i < 10; i++) {
      particles.push(<div key={i} className={`particle particle-${i}`} />);
    }
    return particles;
  };

  // Process message to add highlights
  const processMessage = (message, highlightText) => {
    if (!highlightText) return message;
    
    const parts = message.split(highlightText);
    return (
      <>
        {parts[0]}
        <span className="highlight">{highlightText}</span>
        {parts[1]}
      </>
    );
  };

  return (
    <div 
      ref={stripRef} 
      className="professional-attraction-strip"
    >
      {/* Background particles */}
      <div className="particles-container">
        {generateParticles()}
      </div>
      
      {/* Shimmer effect */}
      <div className="shimmer-effect"></div>
      
      {/* Interactive light effect */}
      <div className="light-effect"></div>
      
      {/* Message container */}
      <div className="message-container">
        {benefits.map((benefit, index) => (
          <div
            key={index}
            className={`benefit-message ${index === currentIndex ? animation : 'hidden'}`}
          >
            <span className="benefit-icon">{benefit.icon}</span>
            <span className="benefit-text">
              {processMessage(benefit.text, benefit.highlight)}
            </span>
          </div>
        ))}
      </div>
      
      {/* Edge borders */}
      <div className="edge-border top"></div>
      <div className="edge-border bottom"></div>
    </div>
  );
};

export default CustomerAttractionStrip;