import React, { useState } from 'react';
import { MapPin, Phone, Mail, Clock, Loader2 } from 'lucide-react';
import axios from '../../helpers/axiosconfig';
import '../../styles/Contact.scss';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: ''
  });

  const [status, setStatus] = useState({
    loading: false,
    error: null,
    success: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, error: null, success: false });

    try {
      await axios.post('/contact', formData);
      
      setStatus({
        loading: false,
        error: null,
        success: true
      });

      // Reset form
      setFormData({
        fullName: '',
        email: '',
        subject: '',
        message: ''
      });

      // Clear success message after 5 seconds
      setTimeout(() => {
        setStatus(prev => ({ ...prev, success: false }));
      }, 5000);

    } catch (error) {
      setStatus({
        loading: false,
        error: error.response?.data?.message || 'Failed to send message. Please try again.',
        success: false
      });

      // Clear error message after 5 seconds
      setTimeout(() => {
        setStatus(prev => ({ ...prev, error: null }));
      }, 5000);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="contact-container">
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Contact Us</h1>
        <p>
          We're here to help and answer any questions you might have. We look forward to hearing from you.
        </p>
      </div>

      {/* Contact Information Cards */}
      <div className="contact-cards">
        <div className="contact-card">
          <MapPin className="icon" />
          <h3>Visit Us</h3>
          <p>PLOT NO 65, SURVEY NO 213-214, LAKHABAVAD,<br />Jamnagar, Gujarat, 361006</p>
        </div>
        <div className="contact-card">
          <Phone className="icon" />
          <h3>Call Us</h3>
          <p>7788994668</p>
          <p className="subtitle">Monday to Saturday: 9:00 AM - 6:00 PM</p>
        </div>
        <div className="contact-card">
          <Mail className="icon" />
          <h3>Email Us</h3>
          <p>CARE@BUYMALAK.IN</p>
          <p className="subtitle">We'll respond within 24 hours</p>
        </div>
        <div className="contact-card">
          <Clock className="icon" />
          <h3>Business Hours</h3>
          <p>Monday - Saturday</p>
          <p className="subtitle">9:00 AM - 6:00 PM</p>
          <p>Sunday: Closed</p>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="form-section">
        <div className="form-container">
          <h2>Send us a Message</h2>

          {/* Status Messages */}
          {status.error && (
            <div className="alert error-alert">
              <p>{status.error}</p>
            </div>
          )}
          
          {status.success && (
            <div className="alert success-alert">
              <p>Thank you for your message. We'll get back to you soon!</p>
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
                minLength="2"
                maxLength="100"
                disabled={status.loading}
                placeholder="Enter your full name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled={status.loading}
                placeholder="Enter your email address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                minLength="5"
                maxLength="200"
                disabled={status.loading}
                placeholder="Enter subject"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                required
                minLength="10"
                maxLength="1000"
                disabled={status.loading}
                placeholder="Enter your message"
              ></textarea>
            </div>
            <button 
              type="submit" 
              className={`submit-button ${status.loading ? 'loading' : ''}`}
              disabled={status.loading}
            >
              {status.loading ? (
                <>
                  <Loader2 className="spinner" />
                  <span>Sending...</span>
                </>
              ) : (
                'Send Message'
              )}
            </button>
          </form>
        </div>

        {/* Map or Additional Info */}
        <div className="map-container">
          <div className="map-placeholder">
            <div className="map-overlay">
              <h3>Location Map</h3>
              <p>Interactive map coming soon</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;