import React, { useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { LayoutDashboard, MessageSquare, Menu, X, LogOut, Package, Tags } from 'lucide-react';
import '../../styles/AdminLayout.scss';

const AdminLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin');
  };

  const menuItems = [
    {
      title: 'Orders',
      path: '/admin/orders',
      icon: <LayoutDashboard size={20} />
    },
    {
      title: 'Products',
      path: '/admin/products',
      icon: <Package size={20} />
    },
    {
      title: 'Coupons',
      path: '/admin/coupons',
      icon: <Tags size={20} />
    },
    {
      title: 'Contact Messages',
      path: '/admin/contacts',
      icon: <MessageSquare size={20} />
    }
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <div className="admin-layout">
      {/* Sidebar */}
      <aside className={`admin-layout__sidebar ${isSidebarOpen ? 'admin-layout__sidebar--open' : ''}`}>
        <div className="admin-layout__sidebar-content">
          <div className="admin-layout__header">
            <h1 className="admin-layout__title">Admin Panel</h1>
            <button
              onClick={() => setSidebarOpen(false)}
              className="admin-layout__close-btn"
            >
              <X size={24} />
            </button>
          </div>
          
          <nav className="admin-layout__nav">
            {menuItems.map((item) => (
              <button
                key={item.path}
                onClick={() => navigate(item.path)}
                className={`admin-layout__nav-item ${
                  isActive(item.path) ? 'admin-layout__nav-item--active' : ''
                }`}
              >
                {item.icon}
                <span>{item.title}</span>
              </button>
            ))}
          </nav>

          <div className="admin-layout__footer">
            <button
              onClick={handleLogout}
              className="admin-layout__logout-btn"
            >
              <LogOut size={20} />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </aside>

      {/* Main Content */}
      <div className="admin-layout__content">
        <header className="admin-layout__mobile-header">
          <button
            onClick={() => setSidebarOpen(true)}
            className="admin-layout__menu-btn"
          >
            <Menu size={24} />
          </button>
        </header>

        <main className="admin-layout__main">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;