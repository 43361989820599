import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Download } from 'lucide-react';
import instance from '../../helpers/axiosconfig';
import '../../styles/Invoice.scss';
import html2pdf from 'html2pdf.js';

const Invoice = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const COD_CHARGES = 25;
  const GSTIN = '24IDRPS6991G1ZK';

  // Format invoice number with M/71721 prefix
  const formatInvoiceNumber = (number) => {
    if (!number && number !== 0) return 'M/71721/N/A';
    return `M/71721/${number}`;
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  const fetchOrderDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }
  
      instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Get the order details
      const response = await instance.get(`/orders/${orderId}`);
      
      if (response.data.success) {
        setOrder(response.data.order);
        console.log("Order details:", response.data.order);
      } else {
        throw new Error(response.data.message || 'Failed to fetch order details');
      }
    } catch (err) {
      setError(err.message);
      console.error('Error fetching order details:', err);
    } finally {
      setLoading(false);
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 2,
    }).format(price);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getProductDetails = (item) => {
    const variation = item.productId?.variations?.find(v => v._id === item.variationId);
    return {
      name: variation?.name || 'Product Name Not Available',
      price: item.price || 0,
      volume: variation?.volume || 'N/A',
      discount: variation?.discount || 0
    };
  };

  const calculateDiscount = (price, discount) => {
    return price * (discount / 100);
  };

  const calculateDeliveryGST = (deliveryCharge) => {
    const baseAmount = Math.round((deliveryCharge / 1.18) * 100) / 100;
    const singleGSTAmount = Math.round((baseAmount * 0.09) * 100) / 100;

    return {
      baseDeliveryAmount: baseAmount,
      deliveryGSTAmount: singleGSTAmount
    };
  };

  const calculateGSTFromTotal = (totalAmount, paymentMethod = '') => {
    const isCOD = (paymentMethod || '').toLowerCase() === 'cod';
    
    const deliveryGST = isCOD ? calculateDeliveryGST(COD_CHARGES) : {
      baseDeliveryAmount: 0,
      deliveryGSTAmount: 0
    };

    const amountBeforeGST = isCOD ? totalAmount - COD_CHARGES : totalAmount;

    const baseAmount = Math.round((amountBeforeGST / 1.18) * 100) / 100;
    const singleGSTAmount = Math.round((baseAmount * 0.09) * 100) / 100;
    
    return {
      baseAmount,
      gstAmount: singleGSTAmount,
      codCharges: isCOD ? COD_CHARGES : 0,
      baseDeliveryAmount: deliveryGST.baseDeliveryAmount,
      deliveryGSTAmount: deliveryGST.deliveryGSTAmount
    };
  };

  const handleDownloadPDF = () => {
    if (!order) return;

    const { 
      baseAmount, 
      gstAmount, 
      codCharges,
      baseDeliveryAmount,
      deliveryGSTAmount 
    } = calculateGSTFromTotal(order.totalAmount, order.paymentMethod);

    const invoice = document.createElement('div');
    invoice.innerHTML = `
      <div class="invoice-pdf" style="padding: 40px; max-width: 800px; margin: 0 auto; font-family: Arial, sans-serif;">
        <div style="text-align: center; margin-bottom: 30px;">
          <h1 style="font-size: 24px; color: #1a1a1a; margin-bottom: 10px;">Invoice ${formatInvoiceNumber(order.invoiceNumber)}</h1>
          <p style="color: #4b5563;">Order ID: ${order.orderId}</p>
        </div>

        <div style="margin-bottom: 30px;">
          <table style="width: 100%; margin-bottom: 20px;">
            <tr>
              <td style="width: 50%; vertical-align: top; padding-right: 20px;">
                <div style="margin-bottom: 20px;">
                  <h3 style="font-size: 16px; color: #374151; margin-bottom: 10px;">From:</h3>
                  <p style="margin: 5px 0; color: #4b5563;">Periodic Industries</p>
                  <p style="margin: 5px 0; color: #4b5563;">PLOT NO 65, SURVEY NO 213-214</p>
                  <p style="margin: 5px 0; color: #4b5563;">LAKHABAVAD, Jamnagar, Gujarat, 361006</p>
                  <p style="margin: 5px 0; color: #4b5563;">GSTIN: ${GSTIN}</p>
                </div>
              </td>
              <td style="width: 50%; vertical-align: top;">
                <div style="margin-bottom: 20px;">
                  <h3 style="font-size: 16px; color: #374151; margin-bottom: 10px;">To:</h3>
                  <p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.name || 'N/A'}</p>
                  <p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.phone || 'N/A'}</p>
                  <p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.email || 'N/A'}</p>
                  <p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.city || 'N/A'}, ${order.shippingAddress?.state || 'N/A'}</p>
                  <p style="margin: 5px 0; color: #4b5563;">${order.shippingAddress?.pincode || 'N/A'}</p>
                </div>
              </td>
            </tr>
          </table>

          <table style="width: 100%; margin-bottom: 20px; background-color: #f9fafb; border-radius: 6px; padding: 15px;">
            <tr>
              <td style="width: 50%;">
                <p style="margin: 0; color: #6b7280; font-size: 14px;">Order Date:</p>
                <p style="margin: 5px 0 0; color: #1f2937; font-weight: 500;">${formatDate(order.createdAt)}</p>
              </td>
              <td style="width: 50%;">
                <p style="margin: 0; color: #6b7280; font-size: 14px;">Payment Details:</p>
                <p style="margin: 5px 0 0; color: #1f2937; font-weight: 500;">
                  ${order.paymentMethod.toUpperCase()} - ${order.paymentStatus.toUpperCase()}
                </p>
              </td>
            </tr>
          </table>
        </div>

        <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
          <thead>
            <tr style="background-color: #f3f4f6;">
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Item</th>
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Volume</th>
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Quantity</th>
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Price</th>
              <th style="padding: 12px; text-align: left; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Discount</th>
              <th style="padding: 12px; text-align: right; font-weight: 600; color: #374151; border-bottom: 2px solid #e5e7eb;">Total</th>
            </tr>
          </thead>
          <tbody>
            ${order.items.map(item => {
              const details = getProductDetails(item);
              const discountAmount = calculateDiscount(details.price, details.discount);
              return `
                <tr>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${details.name}</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${details.volume}</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${item.quantity}</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${formatPrice(details.price)}</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563;">${details.discount}% (${formatPrice(discountAmount)})</td>
                  <td style="padding: 12px; border-bottom: 1px solid #e5e7eb; color: #4b5563; text-align: right;">${formatPrice(item.price * item.quantity)}</td>
                </tr>
              `;
            }).join('')}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563; border-top: 2px solid #e5e7eb;">Base Amount:</td>
              <td style="padding: 12px; color: #4b5563; border-top: 2px solid #e5e7eb; text-align: right;">${formatPrice(baseAmount)}</td>
            </tr>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">CGST (9%):</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(gstAmount)}</td>
            </tr>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">SGST (9%):</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(gstAmount)}</td>
            </tr>
            ${codCharges > 0 ? `
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">Delivery Charges:</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(baseDeliveryAmount)}</td>
            </tr>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">Delivery CGST (9%):</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(deliveryGSTAmount)}</td>
            </tr>
            <tr>
              <td colspan="5" style="padding: 12px; color: #4b5563;">Delivery SGST (9%):</td>
              <td style="padding: 12px; color: #4b5563; text-align: right;">${formatPrice(deliveryGSTAmount)}</td>
            </tr>
            ` : ''}
            <tr>
              <td colspan="5" style="padding: 12px; font-weight: 600; color: #1f2937;">Total Amount:</td>
              <td style="padding: 12px; font-weight: 600; color: #1f2937; text-align: right;">${formatPrice(order.totalAmount)}</td>
            </tr>
          </tfoot>
        </table>

        <div style="margin-top: 40px; text-align: center; color: #6b7280; font-size: 14px;">
          <p>Thank you for your Order!</p>
          <p>This is a computer-generated receipt. No signature is required.</p>
        </div>
      </div>
    `;

    const opt = {
      margin: [15, 15, 15, 15],
      filename: `invoice-${formatInvoiceNumber(order.invoiceNumber)}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        logging: false
      },
      jsPDF: { 
        unit: 'mm', 
        format: 'a4', 
        orientation: 'portrait'
      }
    };

    html2pdf().from(invoice).set(opt).save();
  };

  if (loading) {
    return (
      <div className="loading-state">
        Loading invoice...
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-state">
        Error: {error}
      </div>
    );
  }

  if (!order) {
    return (
      <div className="loading-state">
        No order found
      </div>
    );
  }

  const { 
    baseAmount, 
    gstAmount, 
    codCharges,
    baseDeliveryAmount,
    deliveryGSTAmount 
  } = calculateGSTFromTotal(order.totalAmount, order.paymentMethod);

  return (
    <div className="invoice-container">
      <div className="invoice-card">
        <div className="invoice-header">
          <h1>Invoice {formatInvoiceNumber(order.invoiceNumber)}</h1>
          <p>Order ID: {order.orderId}</p>
          <button 
            onClick={handleDownloadPDF}
            className="download-btn"
          >
            <Download />
            Download PDF
          </button>
        </div>

        <div className="invoice-details">
          <div className="address-block">
            <h3>From:</h3>
            <p>Periodic Industries</p>
            <p>PLOT NO 65, SURVEY NO 213-214</p>
            <p>LAKHABAVAD, Jamnagar, Gujarat, 361006</p>
            <p>GSTIN: {GSTIN}</p>
          </div>
          <div className="address-block">
            <h3>To:</h3>
            <p>{order.shippingAddress?.name}</p>
            <p>{order.shippingAddress?.phone}</p>
            <p>{order.shippingAddress?.email}</p>
            <p>{order.shippingAddress?.city}, {order.shippingAddress?.state}</p>
            <p>{order.shippingAddress?.pincode}</p>
          </div>
        </div>
        
        <div className="order-info">
          <div className="info-item">
            <p className="label">Order Date:</p>
            <p className="value">{formatDate(order.createdAt)}</p>
          </div>
          <div className="info-item">
            <p className="label">Payment Details:</p>
            <p className="value">
              {order.paymentMethod.toUpperCase()} - {order.paymentStatus.toUpperCase()}
            </p>
          </div>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Volume</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {order.items.map((item, index) => {
              const details = getProductDetails(item);
              const discountAmount = calculateDiscount(details.price, details.discount);
              return (
                <tr key={index}>
                  <td>{details.name}</td>
                  <td>{details.volume}</td>
                  <td>{item.quantity}</td>
                  <td>{formatPrice(details.price)}</td>
                  <td>{details.discount}% ({formatPrice(discountAmount)})</td>
                  <td>{formatPrice(item.price * item.quantity)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5}>Base Amount:</td>
              <td>{formatPrice(baseAmount)}</td>
            </tr>
            <tr>
              <td colSpan={5}>CGST (9%):</td>
              <td>{formatPrice(gstAmount)}</td>
            </tr>
            <tr>
              <td colSpan={5}>SGST (9%):</td>
              <td>{formatPrice(gstAmount)}</td>
            </tr>
            {codCharges > 0 && (
              <>
                <tr>
                  <td colSpan={5}>Cash on Delivery Charges:</td>
                  <td>{formatPrice(baseDeliveryAmount)}</td>
                </tr>
                <tr>
                  <td colSpan={5}>Cash on Delivery CGST (9%):</td>
                  <td>{formatPrice(deliveryGSTAmount)}</td>
                </tr>
                <tr>
                  <td colSpan={5}>Cash on Delivery SGST (9%):</td>
                  <td>{formatPrice(deliveryGSTAmount)}</td>
                </tr>
              </>
            )}
            <tr className="total-row">
              <td colSpan={5}>Total Amount:</td>
              <td>{formatPrice(order.totalAmount)}</td>
            </tr>
          </tfoot>
        </table>

        <div className="invoice-footer">
          <p>Thank you for your Order!</p>
        </div>

        <div className="invoice-footer">
          <p>This is a computer-generated receipt. No signature is required.</p>
        </div>
      </div>
    </div>
  );
};

export default Invoice;