import React, { useState, useRef, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import '../../styles/SingleBanner.scss';

const SingleBanner = ({ 
  videoUrl,
  height = '800px'
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [videoError, setVideoError] = useState(false);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const videoRef = useRef(null);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate responsive dimensions
  const getResponsiveHeight = () => {
    if (windowWidth < 640) {
      return '300px'; // Mobile
    } else if (windowWidth < 1024) {
      return '500px'; // Tablet
    } else {
      return height; // Desktop (default)
    }
  };

  const handleVideoLoad = () => {
    setIsLoading(false);
  };

  const handleVideoError = () => {
    setIsLoading(false);
    setVideoError(true);
  };

  return (
    <div 
      className="single-banner-container" 
      style={{ 
        width: '100%',
        display: 'block',
        overflowX: 'hidden',
        padding: 0,
        margin: 0
      }}
    >
      <div 
        className="banner-wrapper"
        style={{ 
          width: '100%',
          maxWidth: '100vw',
          margin: 0,
          padding: 0,
          borderRadius: windowWidth < 640 ? '0' : '0',
          overflow: 'hidden',
          boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)'
        }}
      >
        <div 
          className="banner-content"
          style={{ 
            height: 'auto',
            maxHeight: getResponsiveHeight(),
            position: 'relative',
            backgroundColor: '#000000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            aspectRatio: '16/9'
          }}
        >
          {isLoading && (
            <div className="loader-container" style={{
              position: 'absolute',
              inset: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1
            }}>
              <Loader2 className="animate-spin text-gray-300" size={windowWidth < 640 ? 24 : 32} />
            </div>
          )}
          
          {videoError ? (
            <div style={{
              position: 'absolute',
              inset: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#f3f4f6'
            }}>
              Failed to load video
            </div>
          ) : (
            <video
              ref={videoRef}
              src={videoUrl}
              autoPlay
              loop
              muted
              playsInline
              onLoadedData={handleVideoLoad}
              onError={handleVideoError}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              className={`transition-opacity duration-300 ${
                isLoading ? 'opacity-0' : 'opacity-100'
              }`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleBanner;