import React, { useMemo } from 'react';

const getBoughtCount = (name, volume, fragrance) => {
  const nameLower = name?.toLowerCase() || '';
  const volumeStr = volume?.toLowerCase() || '';

  if (nameLower.includes('liquid detergent')) {
    if (volumeStr.includes('1 l') || volumeStr.includes('1l')) return '2k+';
    if (volumeStr.includes('1.8')) return '3k+';
    if (volumeStr.includes('5')) return '10k+';
  }
  
  if (nameLower.includes('dishwash')) {
    if (volumeStr.includes('900') && volumeStr.includes('2')) return '3k+';
    if (volumeStr.includes('900')) return '500+';
    if (volumeStr.includes('5')) return '5k+';
  }
  
  if (nameLower.includes('toilet cleaner')) {
    if (volumeStr.includes('900') && volumeStr.includes('3')) return '2.4k+';
    if (volumeStr.includes('900') && volumeStr.includes('2')) return '1.3k+';
    if (volumeStr.includes('900')) return '400+';
    if (volumeStr.includes('5')) return '1k+';
  }
  
  if (nameLower.includes('bathroom cleaner')) {
    if (volumeStr.includes('900') && volumeStr.includes('2')) return '6k+';
    if (volumeStr.includes('900')) return '2k+';
  }
  
  if (nameLower.includes('surface cleaner')) {
    const fragranceLower = fragrance?.toLowerCase() || '';
    
    if (fragranceLower.includes('rose')) {
      if (volumeStr.includes('900')) return '600+';
      if (volumeStr.includes('1.8')) return '1.2k+';
      if (volumeStr.includes('5')) return '500+';
    }
    
    if (fragranceLower.includes('lemon')) {
      if (volumeStr.includes('900')) return '700+';
      if (volumeStr.includes('1.8')) return '1.3k+';
      if (volumeStr.includes('5')) return '900+';
    }
    
    if (fragranceLower.includes('lavender')) {
      if (volumeStr.includes('900')) return '400+';
      if (volumeStr.includes('1.8')) return '1.7k+';
      if (volumeStr.includes('5')) return '800+';
    }
    
    if (fragranceLower.includes('jasmin')) {
      if (volumeStr.includes('900')) return '200+';
      if (volumeStr.includes('1.8')) return '1.2k+';
      if (volumeStr.includes('5')) return '850+';
    }
  }
  
  return `${Math.floor(Math.random() * (5000 - 100 + 1) + 100)}+`; // Random between 100-5000
};

const ProductEngagement = ({ product }) => {
  const boughtCount = useMemo(() => {
    if (!product) {
      // Try to get values from parent scope if product prop isn't passed
      const nameElement = document.querySelector('.product-title');
      const name = nameElement?.textContent;
      
      // Look for volume in various places in the DOM
      const volumeElement = document.querySelector('.spec-value, .volume-text');
      const volume = volumeElement?.textContent;
      
      // Look for fragrance in the DOM
      const fragranceElement = document.querySelector('.fragrance .spec-value');
      const fragrance = fragranceElement?.textContent;
      
      return getBoughtCount(name, volume, fragrance);
    }
    
    return getBoughtCount(product.name, product.volume, product.fragrance);
  }, [product]);

  return (
    <div className="bought-count">
      <span>{boughtCount}</span> boughts in last month
    </div>
  );
};

export default ProductEngagement;